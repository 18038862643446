// src/pages/CustomViews.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Pagination,
  Paper,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  Chip,
  InputAdornment,
  Link,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Share as ShareIcon,
  OpenInNew as OpenInNewIcon,
  Folder as FolderIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExpandLess,
  ExpandMore,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../components/ConfirmDialog';
import { api } from '../services/authService';
import ShareDialog from '../components/ShareDialog';

const DRAWER_WIDTH = 240;
const ITEMS_PER_PAGE = 10;

const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) return '-';
  const date = new Date(dateTimeString);
  return date.toLocaleString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).replace(',', '');
};

const CustomViews = () => {
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [selectedViews, setSelectedViews] = useState([]);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [shareRecipients, setShareRecipients] = useState([]);
  const [folders, setFolders] = useState([]);
  const [views, setViews] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [editFolderDialogOpen, setEditFolderDialogOpen] = useState(false);
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
  const [selectedFolderForAction, setSelectedFolderForAction] = useState(null);
  const [editFolderName, setEditFolderName] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [singleDeleteDialogOpen, setSingleDeleteDialogOpen] = useState(false);
  const [viewToShare, setViewToShare] = useState(null);

  // Fetch initial data
  useEffect(() => {
    fetchFolders();
    fetchViews();
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // API calls using the api service
  const fetchFolders = async () => {
    try {
      const response = await api.get('/crm/folders/');
      setFolders(response.data);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  const fetchViews = async () => {
    try {
      const response = await api.get('/crm/custom-views/');
      setViews(response.data);
    } catch (error) {
      console.error('Error fetching views:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1); // Reset pagination when changing tabs
  };

  const handleMenuOpen = (event, view) => {
    setAnchorEl(event.currentTarget);
    setSelectedView(view);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedView(null);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/crm/custom-views/${selectedView?.id}/`);
      setViews(views.filter(view => view.id !== selectedView?.id));
      setSingleDeleteDialogOpen(false);
      handleMenuClose();
    } catch (error) {
      console.error('Error deleting view:', error);
    }
  };

  const handleShare = (view) => {
    if (!view || !view.id || !view.campaign_details || !view.campaign_details.id) {
      console.error('Incomplete view data; cannot open share dialog:', view);
      return;
    }
    setViewToShare(view);
    setShareDialogOpen(true);
    handleMenuClose();
  };

  const toggleDrawer = () => {
    setIsDrawerExpanded(!isDrawerExpanded);
  };

  const toggleFolder = (folderId) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filterViews = (views) => {
    if (!searchQuery) return views;
    
    const searchTerms = searchQuery.toLowerCase().split(' ');
    return views.filter(view => {
      const searchableText = [
        view.name,
        view.description,
        view.creator,
        view.account,
        view.campaign
      ].join(' ').toLowerCase();
      
      return searchTerms.every(term => searchableText.includes(term));
    });
  };

  const getCurrentViews = () => {
    let filteredViews = filterViews(views);
    
    // First filter by tab (My Views vs Shared Views)
    filteredViews = filteredViews.filter(view => {
      if (tabValue === 0) {
        // My Views tab - show only views where user is the creator
        return view.creator === view.creator_details.id;
      } else {
        // Shared Views tab - show only views shared with the user
        return view.creator !== view.creator_details.id;
      }
    });
    
    // Then filter by folder if one is selected
    if (selectedFolder) {
      filteredViews = filteredViews.filter(view => view.folder === selectedFolder);
    }
    
    const start = (page - 1) * ITEMS_PER_PAGE;
    return filteredViews.slice(start, start + ITEMS_PER_PAGE);
  };

  const getTotalPages = () => {
    return Math.ceil(filterViews(views).length / ITEMS_PER_PAGE);
  };

  // Bulk action handlers
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedViews(views.map(view => view.id));
    } else {
      setSelectedViews([]);
    }
  };

  const handleSelectView = (viewId) => {
    setSelectedViews(prev =>
      prev.includes(viewId)
        ? prev.filter(id => id !== viewId)
        : [...prev, viewId]
    );
  };

  const handleBulkMove = () => {
    setMoveDialogOpen(true);
  };

  const handleBulkShare = () => {
    // Get the first selected view's data for sharing
    const viewToShare = views.find(view => selectedViews.includes(view.id));
    if (!viewToShare || !viewToShare.id) {
      console.error('No valid view selected for sharing');
      return;
    }
    setViewToShare(viewToShare);
    setShareDialogOpen(true);
  };

  const handleBulkDelete = () => {
    setDeleteDialogOpen(true);
  };

  const confirmBulkMove = async () => {
    if (selectedFolder) {
      try {
        await api.put('/crm/custom-views/bulk-move/', {
          ids: selectedViews,
          folder: selectedFolder
        });
        await fetchViews(); // Refresh views after move
        setSelectedViews([]);
        setMoveDialogOpen(false);
        setSelectedFolder('');
      } catch (error) {
        console.error('Error moving views:', error);
      }
    }
  };

  const confirmBulkDelete = async () => {
    try {
      await api.delete('/crm/custom-views/bulk_delete/', {
        data: { ids: selectedViews }
      });
      await fetchViews(); // Refresh views after delete
      setSelectedViews([]);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error bulk deleting views:', error);
    }
  };

  const isAllSelected = () => {
    return views.length > 0 && selectedViews.length === views.length;
  };

  const isPageIndeterminate = () => {
    return selectedViews.length > 0 && selectedViews.length < views.length;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(1); // Reset to first page when searching
  };

  // Add create folder handler
  const handleCreateFolder = async () => {
    try {
      await api.post('/crm/folders/', {
        name: newFolderName
      });
      await fetchFolders(); // Refresh folders list
      setNewFolderName('');
      setCreateFolderDialogOpen(false);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  // Add folder edit/delete handlers
  const handleEditFolder = async () => {
    try {
      await api.put(`/crm/folders/${selectedFolderForAction.id}/`, {
        name: editFolderName
      });
      await fetchFolders();
      setEditFolderDialogOpen(false);
      setEditFolderName('');
      setSelectedFolderForAction(null);
    } catch (error) {
      console.error('Error updating folder:', error);
    }
  };

  const handleDeleteFolder = async () => {
    try {
      await api.delete(`/crm/folders/${selectedFolderForAction.id}/`);
      await fetchFolders();
      await fetchViews(); // Refresh views as some might have been deleted
      setDeleteFolderDialogOpen(false);
      setSelectedFolderForAction(null);
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  // Add function to fetch users from campaigns
  const fetchCampaignUsers = async () => {
    try {
      // Get unique campaign IDs from selected views
      const selectedViewsData = views.filter(view => selectedViews.includes(view.id));
      const campaignIds = [...new Set(selectedViewsData.map(view => view.campaign))];
      
      // Fetch users for each campaign
      const usersPromises = campaignIds.map(campaignId => 
        api.get(`/crm/campaigns/${campaignId}/`)
      );
      
      const responses = await Promise.all(usersPromises);
      
      // Combine and deduplicate users from all campaigns
      const allUsers = responses.flatMap(response => 
        response.data.users_with_access.map(access => ({
          id: access.user.id,
          name: `${access.user.first_name} ${access.user.last_name}`.trim() || access.user.email,
          email: access.user.email
        }))
      );
      
      // Remove duplicates based on user ID
      const uniqueUsers = [...new Map(allUsers.map(user => [user.id, user])).values()];
      setAvailableUsers(uniqueUsers);
    } catch (error) {
      console.error('Error fetching campaign users:', error);
    }
  };

  // Update useEffect for share dialog
  useEffect(() => {
    if (shareDialogOpen && selectedViews.length > 0) {
      fetchCampaignUsers();
    }
  }, [shareDialogOpen, selectedViews]);

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Folder Navigation Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: isDrawerExpanded ? DRAWER_WIDTH : 56,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isDrawerExpanded ? DRAWER_WIDTH : 56,
            position: 'sticky',
            top: '64px',
            transition: 'width 0.3s ease',
            overflowX: 'hidden',
            zIndex: (theme) => theme.zIndex.appBar - 1,
          },
        }}
      >
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          {isDrawerExpanded && (
            <>
              <Typography variant="h6">Folders</Typography>
              <Button
                size="small"
                onClick={() => setCreateFolderDialogOpen(true)}
                sx={{ minWidth: 'auto' }}
              >
                + New
              </Button>
            </>
          )}
          <IconButton onClick={toggleDrawer}>
            {isDrawerExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>

        <List sx={{ overflowY: 'auto' }}>
          {/* All Views Option */}
          <ListItem
            sx={{
              bgcolor: selectedFolder === null ? 'action.selected' : 'transparent',
              '&:hover': {
                bgcolor: 'action.hover',
              },
              borderRadius: 1,
              mx: 1,
              mb: 1,
            }}
          >
            <ListItemText 
              primary="All Views"
              sx={{ 
                cursor: 'pointer',
                color: selectedFolder === null ? 'primary.main' : 'text.primary',
              }}
              onClick={() => setSelectedFolder(null)}
            />
          </ListItem>

          {/* Folder List */}
          {folders.map((folder) => (
            <ListItem
              key={folder.id}
              sx={{
                bgcolor: selectedFolder === folder.id ? 'action.selected' : 'transparent',
                '&:hover': {
                  bgcolor: 'action.hover',
                },
                borderRadius: 1,
                mx: 1,
                mb: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                pr: 1,
              }}
            >
              <ListItemText 
                primary={folder.name}
                sx={{ 
                  cursor: 'pointer',
                  color: selectedFolder === folder.id ? 'primary.main' : 'text.primary',
                }}
                onClick={() => setSelectedFolder(folder.id)}
              />
              {isDrawerExpanded && (
                <Box sx={{ visibility: 'hidden', '.MuiListItem-root:hover &': { visibility: 'visible' } }}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFolderForAction(folder);
                      setEditFolderName(folder.name);
                      setEditFolderDialogOpen(true);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFolderForAction(folder);
                      setDeleteFolderDialogOpen(true);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Main Content */}
      <Box sx={{ 
        flexGrow: 1, 
        p: 3,
        width: { sm: `calc(100% - ${isDrawerExpanded ? DRAWER_WIDTH : 56}px)` },
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5" component="h1">
            Custom Views
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/crm/custom-views/create')}
          >
            Create New View
          </Button>
        </Box>

        {/* Search Bar */}
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search views by name, description, creator, account, or campaign..."
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { bgcolor: 'background.paper' }
            }}
          />
        </Box>

        {/* Bulk Actions */}
        {selectedViews.length > 0 && (
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Button onClick={handleBulkShare}>
              Share ({selectedViews.length})
            </Button>
            <Button onClick={handleBulkMove}>
              Move to Folder ({selectedViews.length})
            </Button>
            <Button
              color="error"
              onClick={handleBulkDelete}
            >
              Delete ({selectedViews.length})
            </Button>
          </Box>
        )}

        <Paper sx={{ mb: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="My Views" />
            <Tab label="Shared Views" />
          </Tabs>

          {/* Show "No results found" message when search yields no results */}
          {getCurrentViews().length === 0 ? (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="body1" color="text.secondary">
                {tabValue === 0 
                  ? "You haven't created any views yet. Click 'Create New View' to get started."
                  : "No views have been shared with you."}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ overflowX: 'auto' }}>
              <Table sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAllSelected()}
                        indeterminate={isPageIndeterminate()}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    {windowWidth > 960 && <TableCell>Description</TableCell>}
                    <TableCell>Creator</TableCell>
                    {windowWidth > 1200 && <TableCell>Last Updated</TableCell>}
                    {windowWidth > 600 && <TableCell>Folder</TableCell>}
                    {windowWidth > 1200 && (
                      <>
                        <TableCell>Account</TableCell>
                        <TableCell>Campaign</TableCell>
                      </>
                    )}
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getCurrentViews().map((view) => (
                    <TableRow key={view.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedViews.includes(view.id)}
                          onChange={() => handleSelectView(view.id)}
                        />
                      </TableCell>
                      <TableCell sx={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Link
                          component="button"
                          onClick={() => navigate(`/crm/custom-views/${view.id}`)}
                          sx={{
                            textDecoration: 'none',
                            color: 'primary.main',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                            textAlign: 'left',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block'
                          }}
                        >
                          {view.name}
                        </Link>
                      </TableCell>
                      {windowWidth > 960 && (
                        <TableCell sx={{ maxWidth: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {view.description || '-'}
                        </TableCell>
                      )} 
                      <TableCell>{view.creator_details?.full_name || '-'}</TableCell>
                      {windowWidth > 1200 && <TableCell>{formatDateTime(view.updated_at)}</TableCell>}
                      {windowWidth > 600 && (
                        <TableCell>
                          {folders.find(f => f.id === view.folder)?.name || '-'}
                        </TableCell>
                      )}
                      {windowWidth > 1200 && (
                        <>
                          <TableCell>{view.account_details?.name || '-'}</TableCell>
                          <TableCell>{view.campaign_details?.name || '-'}</TableCell>
                        </>
                      )}
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={(e) => handleMenuOpen(e, view)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <Pagination
              count={getTotalPages()}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Paper>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          navigate(`/crm/custom-views/${selectedView?.id}`);
          handleMenuClose();
        }}>
          <OpenInNewIcon sx={{ mr: 1 }} /> Open
        </MenuItem>
        <MenuItem onClick={() => {
          navigate(`/crm/custom-views/${selectedView?.id}/edit`);
          handleMenuClose();
        }}>
          <EditIcon sx={{ mr: 1 }} /> Edit
        </MenuItem>
        <MenuItem onClick={() => handleShare(selectedView)}>
          <ShareIcon sx={{ mr: 1 }} /> Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSingleDeleteDialogOpen(true);
          }}
          sx={{ color: 'error.main' }}
        >
          <DeleteIcon sx={{ mr: 1 }} /> Delete
        </MenuItem>
      </Menu>

      {/* Update ShareDialog rendering */}
      {viewToShare && (
        <ShareDialog
          open={shareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
            setViewToShare(null);
            setSelectedViews([]); // Clear selected views after bulk share
          }}
          viewId={viewToShare.id}
          onSave={() => {
            fetchViews(); // Refresh the views list
            setShareDialogOpen(false);
            setViewToShare(null);
            setSelectedViews([]); // Clear selected views after bulk share
          }}
        />
      )}

      {/* Confirm Delete Dialog */}
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Selected Views"
        message={
          <Typography variant="body1">
            Are you sure you want to delete the selected views ({selectedViews.length} views)? This action cannot be undone.
          </Typography>
        }
        onConfirm={confirmBulkDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        confirmText="Delete Views"
        confirmColor="error"
        maxWidth="sm"
      />

      {/* Move Dialog */}
      <Dialog 
        open={moveDialogOpen} 
        onClose={() => setMoveDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Move Views to Folder</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Select a destination folder for the selected views ({selectedViews.length} views).
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Select Folder</InputLabel>
            <Select
              value={selectedFolder}
              onChange={(e) => setSelectedFolder(e.target.value)}
              label="Select Folder"
            >
              {folders.map(folder => (
                <MenuItem key={folder.id} value={folder.id}>
                  {folder.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={confirmBulkMove} 
            disabled={!selectedFolder}
            variant="contained"
          >
            Move Views
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Folder Dialog */}
      <Dialog
        open={createFolderDialogOpen}
        onClose={() => setCreateFolderDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Create a new folder to organize your custom views.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            type="text"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setCreateFolderDialogOpen(false);
              setNewFolderName('');
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateFolder}
            variant="contained"
            disabled={!newFolderName.trim()}
          >
            Create Folder
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Folder Dialog */}
      <Dialog
        open={editFolderDialogOpen}
        onClose={() => {
          setEditFolderDialogOpen(false);
          setEditFolderName('');
          setSelectedFolderForAction(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Folder Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            type="text"
            fullWidth
            value={editFolderName}
            onChange={(e) => setEditFolderName(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setEditFolderDialogOpen(false);
              setEditFolderName('');
              setSelectedFolderForAction(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEditFolder}
            variant="contained"
            disabled={!editFolderName.trim()}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Folder Confirmation Dialog */}
      <ConfirmDialog
        open={deleteFolderDialogOpen}
        title="Delete Folder"
        message={
          <Typography>
            Are you sure you want to delete the folder "{selectedFolderForAction?.name}"?
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              Warning: All views in this folder will be deleted as well. If you want to keep the views,
              please move them to another folder or remove their folder assignment first.
            </Typography>
          </Typography>
        }
        onConfirm={handleDeleteFolder}
        onCancel={() => {
          setDeleteFolderDialogOpen(false);
          setSelectedFolderForAction(null);
        }}
        confirmText="Delete Folder"
        confirmColor="error"
        maxWidth="sm"
      />

      {/* Add Single Delete Confirm Dialog */}
      <ConfirmDialog
        open={singleDeleteDialogOpen}
        title="Delete Custom View"
        message={
          <Typography variant="body1">
            Are you sure you want to delete "{selectedView?.name}"? This action cannot be undone.
          </Typography>
        }
        onConfirm={handleDelete}
        onCancel={() => {
          setSingleDeleteDialogOpen(false);
          handleMenuClose();
        }}
        confirmText="Delete View"
        confirmColor="error"
        maxWidth="sm"
      />
    </Box>
  );
};

export default CustomViews; 