// src/components/IntegrationsTable.js

import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  IconButton,
  Tooltip,
  Avatar,
} from '@mui/material';
import {
  OpenInNew as OpenInNewIcon,
  Description as DocumentationIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

// If needed, import your API base URL here for fetch calls
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://your-domain/api';

const IntegrationsTable = ({ data }) => {
  const theme = useTheme();

  // Example: handle edit
  const handleEdit = async (integration) => {
    console.log('Edit clicked for:', integration);
    // Typically you'd open a modal or direct to a page to configure.
    // If you need to call the "configure" action:
    // await fetch(`${API_BASE_URL}/integrations/${integration.id}/configure/`, { ... });
  };

  // Example: handle delete
  const handleDelete = async (integration) => {
    console.log('Delete clicked for:', integration);
    // Typically you'd confirm with the user, then:
    // await fetch(`${API_BASE_URL}/integrations/${integration.id}/`, { method: 'DELETE' });
  };

  return (
    <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.background.paper }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Integration</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Features</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((integration) => (
            <TableRow key={integration.id}>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {integration.icon_url && (
                    <Avatar src={integration.icon_url} alt={integration.name} />
                  )}
                  {integration.name}
                </div>
              </TableCell>
              <TableCell>{integration.description}</TableCell>
              <TableCell>{integration.category_name}</TableCell>
              <TableCell>
                <Chip
                  label={integration.is_active ? 'Active' : 'Inactive'}
                  color={integration.is_active ? 'success' : 'default'}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', gap: '4px' }}>
                  {integration.requires_oauth && (
                    <Tooltip title="Requires OAuth">
                      <Chip
                        label="OAuth"
                        size="small"
                        color={integration.is_oauth_configured ? 'success' : 'warning'}
                      />
                    </Tooltip>
                  )}
                  {integration.webhook_enabled && (
                    <Tooltip title="Webhook Enabled">
                      <Chip
                        label="Webhook"
                        size="small"
                        color={integration.is_webhook_configured ? 'success' : 'warning'}
                      />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
              <TableCell>
                {integration.documentation_url && (
                  <Tooltip title="View Documentation">
                    <IconButton
                      size="small"
                      href={integration.documentation_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DocumentationIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IntegrationsTable;
