import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Alert,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { api } from '../services/authService';

const WorkflowAutomationsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [workflows, setWorkflows] = useState([]);

  // Fetch accounts
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await api.get('/crm/accounts/');
        setAccounts(response.data);
      } catch (err) {
        setError('Failed to fetch accounts');
        console.error('Error fetching accounts:', err);
      }
    };
    fetchAccounts();
  }, []);

  // Fetch workflows when account is selected
  useEffect(() => {
    const fetchWorkflows = async () => {
      if (!selectedAccount) return;
      
      setLoading(true);
      setError(null);
      try {
        const response = await api.get('/reporting/workflows/', {
          params: { account: selectedAccount }
        });
        setWorkflows(response.data);
      } catch (err) {
        setError('Failed to fetch workflows');
        console.error('Error fetching workflows:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflows();
  }, [selectedAccount]);

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
  };

  const handleViewWorkflow = (workflowId) => {
    navigate(`/crm/automations/workflows/${workflowId}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="text.primary" gutterBottom>
        Workflow Automations
      </Typography>

      {/* Account Selection */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel>Select Account</InputLabel>
          <Select
            value={selectedAccount}
            onChange={handleAccountChange}
            label="Select Account"
          >
            {accounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>

      {/* Workflows Table */}
      {error ? (
        <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
      ) : loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedAccount ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Campaign</TableCell>
                <TableCell>Funnel</TableCell>
                <TableCell>Platform</TableCell>
                <TableCell>Workflow Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflows.map((workflow) => (
                <TableRow key={workflow.id}>
                  <TableCell>{workflow.campaign_details?.name || 'N/A'}</TableCell>
                  <TableCell>{workflow.funnel_details?.name || 'N/A'}</TableCell>
                  <TableCell>{workflow.platform_details?.name || 'N/A'}</TableCell>
                  <TableCell>{workflow.workflow_type_details?.name || 'N/A'}</TableCell>
                  <TableCell>
                    <Chip
                      label={workflow.active ? "Active" : "Inactive"}
                      color={workflow.active ? "success" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="View Workflow">
                      <IconButton
                        size="small"
                        onClick={() => handleViewWorkflow(workflow.id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {workflows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No workflows found for this account
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity="info">Please select an account to view workflows</Alert>
      )}
    </Box>
  );
};

export default WorkflowAutomationsPage; 