import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTasks, deleteTaskThunk } from '../redux/tasksSlice';
import { getScheduledReachOuts, deleteScheduledReachOutThunk } from '../redux/scheduledReachOutsSlice';
import {
  Box, Typography, FormControl, InputLabel, Select, MenuItem, 
  Snackbar, Alert, Tabs, Tab
} from '@mui/material';
import CreateTaskDialog from '../components/CreateTaskDialog';
import EditTaskDialog from '../components/EditTaskDialog';
import CreateScheduledReachOutDialog from '../components/CreateScheduledReachOutDialog';
import EditScheduledReachOutDialog from '../components/EditScheduledReachOutDialog';
import TaskManagementView from '../components/TaskManagementView';
import ScheduledReachOutsView from '../components/ScheduledReachOutsView';
import { api } from '../services/authService';
import { useTheme } from '@mui/material/styles';

const ToDoPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Redux state
  const tasks = useSelector((state) => state.tasks.tasks);
  const scheduledReachOuts = useSelector((state) => state.scheduledReachOuts.scheduledReachOuts);
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.tasks.status === 'loading');

  // State for dialogs
  const [createTaskDialog, setCreateTaskDialog] = useState(false);
  const [editTaskDialog, setEditTaskDialog] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [createReachOutDialog, setCreateReachOutDialog] = useState(false);
  const [editReachOutDialog, setEditReachOutDialog] = useState(false);
  const [reachOutToEdit, setReachOutToEdit] = useState(null);

  // State for accounts and campaigns
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [leads, setLeads] = useState([]); // Keep existing leads state

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // State for view mode
  const [viewMode, setViewMode] = useState('priority');

  // Fetch initial data
  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchCampaigns(selectedAccount);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedCampaign) {
      dispatch(getTasks(selectedCampaign));
      dispatch(getScheduledReachOuts(selectedCampaign));
      fetchLeads();
    }
  }, [dispatch, selectedCampaign]);

  // Fetch functions
  const fetchAccounts = async () => {
    try {
      const response = await api.get('/crm/accounts/');
      setAccounts(response.data);
      if (response.data.length > 0) {
        setSelectedAccount(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      showSnackbar('Error fetching accounts', 'error');
    }
  };

  const fetchCampaigns = async (accountId) => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/campaigns/`);
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      showSnackbar('Error fetching campaigns', 'error');
    }
  };

  const fetchLeads = async () => {
    try {
      const response = await api.get(`/crm/leads/?campaign_id=${selectedCampaign}`);
      setLeads(response.data.results || []);
    } catch (error) {
      console.error('Error fetching leads:', error);
      showSnackbar('Error fetching leads', 'error');
    }
  };

  // Handler functions
  const handleCreateTask = () => {
    setCreateTaskDialog(true);
  };

  const handleEditTask = (task) => {
    setTaskToEdit(task);
    setEditTaskDialog(true);
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await dispatch(deleteTaskThunk(taskId)).unwrap();
      showSnackbar('Task deleted successfully', 'success');
    } catch (error) {
      showSnackbar('Error deleting task', 'error');
    }
  };

  const handleCreateCallback = (params = {}) => {
    setCreateReachOutDialog(true);
  };

  const handleEditCallback = (reachOut) => {
    setReachOutToEdit(reachOut);
    setEditReachOutDialog(true);
  };

  const handleDeleteCallback = async (reachOutId) => {
    try {
      await dispatch(deleteScheduledReachOutThunk(reachOutId)).unwrap();
      showSnackbar('Callback deleted successfully', 'success');
    } catch (error) {
      showSnackbar('Error deleting callback', 'error');
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom color="textPrimary">
        Task Management
      </Typography>

      {/* Account and Campaign Selection */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="account-select-label">Select Account</InputLabel>
        <Select
          labelId="account-select-label"
          value={selectedAccount}
          onChange={(e) => setSelectedAccount(e.target.value)}
          label="Select Account"
        >
          {accounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" disabled={!selectedAccount}>
        <InputLabel id="campaign-select-label">Select Campaign</InputLabel>
        <Select
          labelId="campaign-select-label"
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
          label="Select Campaign"
        >
          {campaigns.map((campaign) => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedCampaign && (
        <>
          <TaskManagementView
            tasks={tasks}
            scheduledCallbacks={scheduledReachOuts}
            leads={leads}
            onEditTask={handleEditTask}
            onDeleteTask={handleDeleteTask}
            onCreateTask={handleCreateTask}
            onEditCallback={handleEditCallback}
            onDeleteCallback={handleDeleteCallback}
            onCreateCallback={handleCreateCallback}
            onLeadClick={(leadId) => navigate(`/crm/leads/${leadId}/details`)}
            userTimezone={user?.timezone}
            theme={theme}
            isLoading={isLoading}
          />

          {/* Dialogs */}
          <CreateTaskDialog
            open={createTaskDialog}
            handleClose={() => setCreateTaskDialog(false)}
            campaignId={selectedCampaign}
            accountId={selectedAccount}
          />

          {taskToEdit && (
            <EditTaskDialog
              open={editTaskDialog}
              handleClose={() => {
                setEditTaskDialog(false);
                setTaskToEdit(null);
              }}
              task={taskToEdit}
              campaignId={selectedCampaign}
              accountId={selectedAccount}
            />
          )}

          <CreateScheduledReachOutDialog
            open={createReachOutDialog}
            handleClose={() => setCreateReachOutDialog(false)}
            campaignId={selectedCampaign}
            accountId={selectedAccount}
            onSuccess={(message) => showSnackbar(message, 'success')}
            onError={(message) => showSnackbar(message, 'error')}
          />

          {reachOutToEdit && (
            <EditScheduledReachOutDialog
              open={editReachOutDialog}
              handleClose={() => {
                setEditReachOutDialog(false);
                setReachOutToEdit(null);
              }}
              reachOut={reachOutToEdit}
              campaign={selectedCampaign}
            />
          )}

          {/* Snackbar */}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert 
              onClose={() => setSnackbar({ ...snackbar, open: false })} 
              severity={snackbar.severity}
              sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </>
      )}
    </Box>
  );
};

export default ToDoPage;