import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { api } from '../services/authService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../redux/snackbarSlice';

const ShareDialog = ({ open, onClose, viewId, onSave }) => {
  const dispatch = useDispatch();
  const [sharedUsers, setSharedUsers] = useState([]);
  const [users, setUsers] = useState([]);

  // Fetch available users when dialog opens
  useEffect(() => {
    if (open && viewId) {
      fetchAvailableUsers();
    }
  }, [open, viewId]);

  const fetchAvailableUsers = async () => {
    try {
      const response = await api.get(`/crm/custom-views/${viewId}/available_users/`);
      const availableUsers = response.data.map(user => ({
        id: user.id,
        name: user.name.trim() || user.email,
        email: user.email,
        permissions: {
          can_edit: false,
          can_view: true,
          can_share: false,
          can_delete: false
        }
      }));
      setUsers(availableUsers);
    } catch (error) {
      console.error('Error fetching available users:', error);
    }
  };

  const handleAddUser = (userId) => {
    const userToAdd = users.find(u => u.id === userId);
    if (!userToAdd) return;

    setSharedUsers(prev => [...prev, {
      id: userId,
      name: userToAdd.name,
      email: userToAdd.email,
      permissions: {
        can_edit: false,
        can_view: true,
        can_share: false,
        can_delete: false
      }
    }]);
  };

  const handleRemoveUser = (userId) => {
    setSharedUsers(prev => prev.filter(user => user.id !== userId));
  };

  const handlePermissionChange = (userId, permission) => {
    setSharedUsers(prev => prev.map(user => {
      if (user.id === userId) {
        return {
          ...user,
          permissions: {
            ...user.permissions,
            [permission]: !user.permissions[permission]
          }
        };
      }
      return user;
    }));
  };

  const handleSave = async () => {
    const shareData = {
      users: sharedUsers.map(user => ({
        user_id: user.id,
        can_edit: user.permissions.can_edit,
        can_share: user.permissions.can_share,
        can_delete: user.permissions.can_delete
        // can_view is handled by your backend with a default
      }))
    };
  
    try {
      await api.post(`/crm/custom-views/${viewId}/share/`, shareData);
      
      // Show success message
      dispatch(showSnackbar({
        message: 'Custom view shared successfully',
        severity: 'success'
      }));
      
      onSave();
      handleClose();
    } catch (error) {
      console.error('Error sharing view:', error);
      console.error('Response data:', error.response?.data);
      
      // Show error message
      dispatch(showSnackbar({
        message: error.response?.data?.error || 'Error sharing custom view',
        severity: 'error'
      }));
    }
  };

  const handleClose = () => {
    setSharedUsers([]);
    onClose();
  };

  // Get users that haven't been added yet
  const availableUsers = users.filter(user => 
    !sharedUsers.some(shared => shared.id === user.id)
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Share Custom View</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Share with Users
          </Typography>
          
          {/* User selector dropdown */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Add User</InputLabel>
            <Select
              value=""
              onChange={(e) => handleAddUser(e.target.value)}
              label="Add User"
            >
              {availableUsers.map(user => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name || user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* List of shared users */}
          <Paper variant="outlined" sx={{ mt: 2 }}>
            <List>
              {sharedUsers.map((user, index) => (
                <React.Fragment key={user.id}>
                  {index > 0 && <Divider />}
                  <ListItem
                    secondaryAction={
                      <IconButton 
                        edge="end" 
                        aria-label="remove" 
                        onClick={() => handleRemoveUser(user.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={user.name || user.email}
                      secondary={
                        <Box sx={{ mt: 1 }}>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={user.permissions.can_view}
                                  onChange={() => handlePermissionChange(user.id, 'can_view')}
                                  size="small"
                                />
                              }
                              label="View"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={user.permissions.can_edit}
                                  onChange={() => handlePermissionChange(user.id, 'can_edit')}
                                  size="small"
                                />
                              }
                              label="Edit"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={user.permissions.can_share}
                                  onChange={() => handlePermissionChange(user.id, 'can_share')}
                                  size="small"
                                />
                              }
                              label="Share"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={user.permissions.can_delete}
                                  onChange={() => handlePermissionChange(user.id, 'can_delete')}
                                  size="small"
                                />
                              }
                              label="Delete"
                            />
                          </FormGroup>
                        </Box>
                      }
                    />
                  </ListItem>
                </React.Fragment>
              ))}
              {sharedUsers.length === 0 && (
                <ListItem>
                  <ListItemText 
                    primary="No users added"
                    secondary="Select users from the dropdown above to share this view"
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained" 
          disabled={sharedUsers.length === 0}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog; 