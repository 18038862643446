// src/pages/AutomationsPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  Dialog,
  Grid,
  Pagination,
  Paper,
  ButtonGroup,
  Button,
} from '@mui/material';
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  NavigateNext as NextIcon,
  NavigateBefore as PrevIcon,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import CategorySidebar from '../components/CategorySidebar';
import PlatformCard from '../components/PlatformCard';
import { api } from '../services/authService';

const CreateAutomationsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // State
  const [platforms, setPlatforms] = useState([]);
  const [platformCategories, setPlatformCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  
  // Pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;

  // --------------------------------------------------------------------
  //  Fetch: Platform Categories
  // --------------------------------------------------------------------
  const fetchPlatformCategories = async () => {
    try {
      const response = await api.get('/reporting/platform-categories/');
      setPlatformCategories(response.data);
    } catch (err) {
      console.error('Error fetching platform categories:', err);
      setError(err.message || 'Failed to fetch categories.');
    }
  };

  // --------------------------------------------------------------------
  //  Fetch: Platforms
  // --------------------------------------------------------------------
  const fetchPlatforms = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/reporting/platforms/', {
        params: {
          category: selectedCategory !== 'All Categories' 
            ? platformCategories.find(c => c.code === selectedCategory)?.id 
            : undefined,
        }
      });
      setPlatforms(response.data);
    } catch (err) {
      setError(err.message || 'Failed to fetch platforms.');
      console.error('Error fetching platforms:', err);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------------------------------------
  //  useEffect: Load data on mount
  // --------------------------------------------------------------------
  useEffect(() => {
    fetchPlatformCategories();
  }, []);

  // --------------------------------------------------------------------
  //  useEffect: Load data when category changes
  // --------------------------------------------------------------------
  useEffect(() => {
    if (platformCategories.length > 0) {  // Only fetch if categories are loaded
      fetchPlatforms();
    }
  }, [selectedCategory, platformCategories]); // Add platformCategories as dependency

  // --------------------------------------------------------------------
  //  Derived: Filter and Paginate Platforms
  // --------------------------------------------------------------------
  const filteredPlatforms = platforms.filter((platform) => {
    const searchTerm = searchQuery.toLowerCase();
    const matchesSearch = 
      platform.name.toLowerCase().includes(searchTerm) ||
      platform.description?.toLowerCase().includes(searchTerm) ||
      platform.platform_category?.name.toLowerCase().includes(searchTerm);

    const matchesCategory = 
      selectedCategory === 'All Categories' ||
      platform.platform_category?.code === selectedCategory;

    return matchesSearch && matchesCategory;
  });

  const paginatedPlatforms = filteredPlatforms.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const pageCount = Math.ceil(filteredPlatforms.length / itemsPerPage);

  // --------------------------------------------------------------------
  //  Handlers
  // --------------------------------------------------------------------
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Sidebar */}
      {!isMobile ? (
        <Box
          sx={{
            width: 240,
            flexShrink: 0,
            backgroundColor: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <CategorySidebar
            categories={platformCategories}
            selectedCategory={selectedCategory}
            onSelectCategory={setSelectedCategory}
          />
        </Box>
      ) : (
        <Box>
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: theme.palette.text.primary }} />
          </IconButton>
          <Dialog open={mobileOpen} onClose={handleDrawerToggle} fullScreen>
            <CategorySidebar
              categories={platformCategories}
              selectedCategory={selectedCategory}
              onSelectCategory={setSelectedCategory}
            />
          </Dialog>
        </Box>
      )}

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">
            {selectedCategory === 'All Categories' 
              ? 'All Platforms' 
              : platformCategories.find(c => c.code === selectedCategory)?.name || selectedCategory}
          </Typography>
        </Box>

        {/* Search */}
        <TextField
          fullWidth
          margin="normal"
          placeholder="Search platforms..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Content */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Paper 
            elevation={3} 
            sx={{ 
              p: 3,
              mt: 2,
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
            }}
          >
            <Grid 
              container 
              spacing={3} 
              sx={{ 
                mb: 4,
                minHeight: { xs: 'auto', md: '200px' }, // Reduced minimum height
                alignItems: 'flex-start', // Align items to the top
                '& .MuiGrid-item': {
                  display: 'flex',
                  width: '100%',
                },
              }}
            >
              {paginatedPlatforms.map((platform) => (
                <Grid 
                  item 
                  xs={12} 
                  sm={6} 
                  md={4} 
                  key={platform.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    height: '100%', // Ensure consistent height
                  }}
                >
                  <Box sx={{ width: '100%' }}> {/* Wrapper to ensure full width */}
                    <PlatformCard platform={platform} />
                  </Box>
                </Grid>
              ))}
              {/* Add empty grid items to maintain layout */}
              {paginatedPlatforms.length > 0 && paginatedPlatforms.length < 3 && 
                Array(3 - paginatedPlatforms.length)
                  .fill(null)
                  .map((_, index) => (
                    <Grid 
                      item 
                      xs={12} 
                      sm={6} 
                      md={4} 
                      key={`empty-${index}`}
                      sx={{ visibility: 'hidden' }}
                    />
                  ))
              }
            </Grid>
            
            {pageCount > 1 && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  mt: 4,
                  borderTop: `1px solid ${theme.palette.divider}`,
                  pt: 3,
                }}
              >
                <ButtonGroup variant="outlined" size="small">
                  <IconButton 
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton 
                    onClick={() => setPage(prev => Math.max(1, prev - 1))}
                    disabled={page === 1}
                  >
                    <PrevIcon />
                  </IconButton>
                </ButtonGroup>

                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                  siblingCount={1}
                  boundaryCount={1}
                />

                <ButtonGroup variant="outlined" size="small">
                  <IconButton 
                    onClick={() => setPage(prev => Math.min(pageCount, prev + 1))}
                    disabled={page === pageCount}
                  >
                    <NextIcon />
                  </IconButton>
                  <IconButton 
                    onClick={() => setPage(pageCount)}
                    disabled={page === pageCount}
                  >
                    <LastPageIcon />
                  </IconButton>
                </ButtonGroup>

                <Typography variant="body2" color="text.secondary">
                  {`${(page - 1) * itemsPerPage + 1}-${Math.min(page * itemsPerPage, filteredPlatforms.length)} of ${filteredPlatforms.length}`}
                </Typography>
              </Box>
            )}
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default CreateAutomationsPage;
