// src/pages/CustomViewEdit.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Paper,
  FormGroup,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../services/authService';

// Assume your constants (BASE_COLUMNS, D2C_COLUMNS, B2B_COLUMNS, FIELD_TYPES, OPERATORS_BY_TYPE, FIELD_CHOICES, FILTER_CATEGORIES) are imported or defined above.
// Define base columns (these are standard fields in your Lead model)
const BASE_COLUMNS = [
    { field_identifier: 'id', display_name: 'ID' },
    { field_identifier: 'lead_type', display_name: 'Lead Type' },
    { field_identifier: 'campaign', display_name: 'Campaign' },
    { field_identifier: 'funnel', display_name: 'Funnel' },
    { field_identifier: 'first_name', display_name: 'First Name' },
    { field_identifier: 'last_name', display_name: 'Last Name' },
    { field_identifier: 'email', display_name: 'Email' },
    { field_identifier: 'phone_number', display_name: 'Phone Number' },
    { field_identifier: 'current_step', display_name: 'Current Step' },
    { field_identifier: 'status', display_name: 'Status' },
    { field_identifier: 'assigned_to', display_name: 'Assigned To' },
    { field_identifier: 'last_updated_by', display_name: 'Last Updated By' },
    { field_identifier: 'channel', display_name: 'Channel' },
    { field_identifier: 'source', display_name: 'Source' },
    { field_identifier: 'score', display_name: 'Score' },
    { field_identifier: 'conversion_probability', display_name: 'Conversion Probability' },
    { field_identifier: 'created_at', display_name: 'Created At' },
    { field_identifier: 'updated_at', display_name: 'Updated At' },
    { field_identifier: 'last_contact_date', display_name: 'Last Contact Date' },
    { field_identifier: 'is_disqualified', display_name: 'Is Disqualified' }
  ];
  
  // Define D2C columns
  const D2C_COLUMNS = [
    { field_identifier: 'address', display_name: 'Address' },
    { field_identifier: 'city', display_name: 'City' },
    { field_identifier: 'state', display_name: 'State' },
    { field_identifier: 'zip_code', display_name: 'Zip Code' }
  ];
  
  // Define B2B columns
  const B2B_COLUMNS = [
    { field_identifier: 'company', display_name: 'Company' },
    { field_identifier: 'job_title', display_name: 'Job Title' },
    { field_identifier: 'industry', display_name: 'Industry' }
  ];

  // Add filter category definitions
const FILTER_CATEGORIES = {
    campaign: {
      title: 'Campaign Filters',
      fields: ['funnel', 'channel', 'source']
    },
    lead: {
      title: 'Lead Filters',
      fields: [
        'first_name', 'last_name', 'email', 'phone_number',
        'current_step', 'status', 'assigned_to', 'score',
        'conversion_probability', 'created_at', 'updated_at',
        'last_contact_date', 'is_qualified', 'is_disqualified', 'is_dead'
      ]
    },
    d2c: {
      title: 'D2C Model Filters',
      fields: ['date_of_birth', 'age', 'gender', 'marital_status']
    },
    b2b: {
      title: 'B2B Model Filters',
      fields: ['company', 'job_title', 'industry', 'company_size', 'annual_revenue']
    },
    intake: {
      title: 'Intake Filters',
      fields: [] // Will be populated from campaign data
    },
    custom: {
      title: 'Custom Field Filters',
      fields: [] // Will be populated from campaign data
    }
  };  

const CustomViewEditSinglePage = () => {
  const { id: viewId } = useParams();
  const navigate = useNavigate();

  // State for the view configuration
  const [viewName, setViewName] = useState('');
  const [viewDescription, setViewDescription] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);

  // State for data lists
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [folders, setFolders] = useState([]);
  const [users, setUsers] = useState([]);
  const [campaignModelType, setCampaignModelType] = useState(null);
  const [columns, setColumns] = useState({
    base: [],
    d2c: [],
    b2b: [],
    intake: [],
    definition: []
  });
  const [campaignFunnels, setCampaignFunnels] = useState([]);
  const [availableSteps, setAvailableSteps] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);

  // --- Fetch initial data on mount ---
  useEffect(() => {
    fetchAccounts();
    fetchFolders();
    if (viewId) {
      fetchCustomView();
      fetchAvailableUsers();
    }
  }, [viewId]);

  // When campaign is selected, fetch campaigns and columns as needed
  useEffect(() => {
    if (selectedCampaign) {
      fetchCampaigns(selectedCampaign);
      fetchColumns();
    }
  }, [selectedCampaign]);

  // --- API Calls ---
  const fetchCustomView = async () => {
    try {
      const response = await api.get(`/crm/custom-views/${viewId}/`);
      const data = response.data;
      setViewName(data.name);
      setViewDescription(data.description || '');
      setSelectedCampaign(data.campaign);
      setSelectedFolder(data.folder || '');
      setSelectedColumns(data.columns.map(col => col.field_identifier));
      
      // Transform filter columns back to their original format
      setFilters(data.filters.map(f => {
        let column = f.column;
        // Remove the prefix for intake fields
        if (column.startsWith('intake_field_')) {
          column = column.replace('intake_field_', '');
        }
        // Remove the prefix for lead fields
        if (column.startsWith('lead_field_')) {
          column = column.replace('lead_field_', '');
        }
        return {
          column,
          operator: f.operator,
          value: f.value,
          order: f.order,
        };
      }));

      // Updated transformation of shares data
      setSharedUsers(data.shares.map(share => ({
        id: share.user,  // Use just the user ID
        name: `${share.user_first_name} ${share.user_last_name}`.trim() || share.user_email,
        email: share.user_email,
        permissions: {
          can_edit: share.can_edit || false,
          can_share: share.can_share || false,
          can_delete: share.can_delete || false,
          can_view: true
        }
      })));
    } catch (error) {
      console.error('Error fetching custom view:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/crm/accounts/');
      setAccounts(response.data);
      // Optionally set account info here if needed.
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchCampaigns = async (accountId) => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/campaigns/`);
      setCampaigns(response.data);
      // For edit, campaign might already be set from fetchCustomView.
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchColumns = async () => {
    if (!selectedCampaign) {
      setColumns({
        base: BASE_COLUMNS.map(col => ({ ...col, column_type: 'base' })),
        d2c: D2C_COLUMNS.map(col => ({ ...col, column_type: 'd2c' })),
        b2b: B2B_COLUMNS.map(col => ({ ...col, column_type: 'b2b' })),
        intake: [],
        definition: []
      });
      return;
    }
    try {
      const [intakeRes, definitionRes] = await Promise.all([
        api.get(`/crm/intake_sections/?campaign_id=${selectedCampaign}`),
        api.get(`/crm/lead_field_definitions/?campaign_id=${selectedCampaign}`)
      ]);
      const intakeFields = intakeRes.data.reduce((fields, section) => {
        return fields.concat(section.intake_fields.map(field => ({
          field_identifier: field.id.toString(),
          display_name: `${section.name} - ${field.name}`,
          column_type: 'intake',
          intake_field: field.id,
          section_name: section.name
        })));
      }, []);
      setColumns({
        base: BASE_COLUMNS.map(col => ({ ...col, column_type: 'base' })),
        d2c: D2C_COLUMNS.map(col => ({ ...col, column_type: 'd2c' })),
        b2b: B2B_COLUMNS.map(col => ({ ...col, column_type: 'b2b' })),
        intake: intakeFields,
        definition: definitionRes.data.map(field => ({
          field_identifier: field.id.toString(),
          display_name: field.name,
          column_type: 'definition',
          lead_field_definition: field.id
        }))
      });
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await api.get('/crm/folders/');
      setFolders(response.data);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  const fetchCampaignFunnels = async () => {
    if (!selectedCampaign) return;
    try {
      const response = await api.get(`/crm/campaigns/${selectedCampaign}/funnels/`);
      setCampaignFunnels(response.data);
      const steps = response.data.reduce((acc, funnel) => {
        funnel.steps.forEach(step => {
          if (!acc.some(s => s.id === step.id)) {
            acc.push({ ...step, funnelName: funnel.name, funnelId: funnel.id });
          }
        });
        return acc;
      }, []);
      setAvailableSteps(steps);
    } catch (error) {
      console.error('Error fetching campaign funnels:', error);
    }
  };

  // Add the fetch function for available users
  const fetchAvailableUsers = async () => {
    try {
      const response = await api.get(`/crm/custom-views/${viewId}/available_users/`);
      setAvailableUsers(response.data.map(user => ({
        id: user.id,
        name: user.name,
        email: user.email
      })));
    } catch (error) {
      console.error('Error fetching available users:', error);
    }
  };

  // --- Handlers for column selection and filters ---
  const handleColumnToggle = (columnId) => {
    setSelectedColumns(prev =>
      prev.includes(columnId)
        ? prev.filter(id => id !== columnId)
        : [...prev, columnId]
    );
  };

  const addFilter = (category) => {
    let availableFields = [];
    if (category === 'intake') {
      availableFields = columns.intake.map(col => col.field_identifier);
    } else if (category === 'custom') {
      availableFields = columns.definition.map(col => col.field_identifier);
    } else {
      availableFields = FILTER_CATEGORIES[category].fields;
    }
    const newFilter = { column: availableFields[0] || '', operator: '', value: '' };
    setFilters(prev => [...prev, newFilter]);
  };

  const updateFilter = (index, field, value) => {
    setFilters(prev => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };
      return updated;
    });
  };

  const removeFilter = (index) => {
    setFilters(prev => prev.filter((_, i) => i !== index));
  };

  const prepareColumnPayload = () => {
    return selectedColumns.map((columnId, index) => {
      let columnData;
      for (const type in columns) {
        const found = columns[type].find(col =>
          col.field_identifier === columnId || col.id === columnId
        );
        if (found) {
          columnData = found;
          break;
        }
      }
      if (!columnData) return null;
      return {
        column_type: columnData.column_type,
        field_identifier: columnData.field_identifier,
        display_name: columnData.display_name,
        order: index + 1,
        visible: true,
        width: '200',
        alignment: 'left',
        intake_field: columnData.intake_field || null,
        lead_field_definition: columnData.lead_field_definition || null,
      };
    }).filter(Boolean);
  };

  const prepareFilterPayload = () => {
    return filters.map((filter, index) => {
      let col = filter.column;
      // Check if the filter column matches an intake field
      const intakeMatch = columns.intake.find(c => c.field_identifier === col);
      if (intakeMatch) {
        col = `intake_field_${col}`;
      }
      // Check if it matches a definition (custom) field
      const definitionMatch = columns.definition.find(c => c.field_identifier === col);
      if (definitionMatch) {
        col = `lead_field_${col}`;
      }
      return {
        ...filter,
        order: index,
        column: col
      };
    });
  };

  const handlePermissionChange = (userId, permission) => {
    setSharedUsers(prev => {
      const userIndex = prev.findIndex(u => u.id === userId);
      if (userIndex === -1) return prev;
      
      const updatedUsers = [...prev];
      updatedUsers[userIndex] = {
        ...updatedUsers[userIndex],
        permissions: {
          ...updatedUsers[userIndex].permissions,
          [permission]: !updatedUsers[userIndex].permissions[permission]
        }
      };
      return updatedUsers;
    });
  };

  // Update handleAddUser to use availableUsers instead of users
  const handleAddUser = (userId) => {
    const userToAdd = availableUsers.find(u => u.id === userId);
    if (!userToAdd) return;

    setSharedUsers(prev => [...prev, {
      id: userId,
      name: userToAdd.name,
      email: userToAdd.email,
      permissions: {
        can_edit: false,
        can_view: true,
        can_share: false,
        can_delete: false
      }
    }]);
  };

  const handleRemoveUser = (userId) => {
    setSharedUsers(prev => prev.filter(user => user.id !== userId));
  };

  const handleSave = async () => {
    // Filter out any invalid user objects that don't have an id
    const validSharedUsers = sharedUsers.filter(user => user && user.id);
    
    const payload = {
      name: viewName,
      description: viewDescription,
      campaign: selectedCampaign,
      folder: selectedFolder || null,  // Make sure null is sent, not empty string
      columns: prepareColumnPayload(),
      filters: prepareFilterPayload(),
      shares: validSharedUsers.map(user => ({
        user: user.id,
        can_edit: user.permissions.can_edit || false,
        can_share: user.permissions.can_share || false,
        can_delete: user.permissions.can_delete || false,
        // No need to set can_view as it's handled by the backend
      })),
    };
  
    try {
      const response = await api.put(`/crm/custom-views/${viewId}/`, payload);
    } catch (error) {
      console.error('Error updating view:', error);
      console.error('Error response:', error.response?.data);
      alert(`Error updating view: ${JSON.stringify(error.response?.data || error.message)}`);
    }
  };

  const handleCancel = () => {
    navigate('/crm/custom-views');
  };

  // --- Render functions ---

  // General Information section
  const renderGeneralInfoSection = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>General Information</Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Campaign</InputLabel>
        <Select
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
          label="Select Campaign"
          disabled // Disable changing campaign in edit mode if desired
        >
          {campaigns.map(campaign => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="View Name"
        value={viewName}
        onChange={(e) => setViewName(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Description"
        value={viewDescription}
        onChange={(e) => setViewDescription(e.target.value)}
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Folder (Optional)</InputLabel>
        <Select
          value={selectedFolder}
          onChange={(e) => setSelectedFolder(e.target.value)}
          label="Select Folder (Optional)"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {folders.map(folder => (
            <MenuItem key={folder.id} value={folder.id}>
              {folder.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* You can optionally add sharing options here */}
    </Box>
  );

  // Column Selection section
  const renderColumnsSection = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>Column Selection</Typography>
      {Object.keys(columns).map(category => (
        <Box key={category} sx={{ mb: 2 }}>
          <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>{category}</Typography>
          <FormGroup row>
            {columns[category].map(col => (
              <FormControlLabel
                key={col.field_identifier}
                control={
                  <Checkbox
                    checked={selectedColumns.includes(col.field_identifier)}
                    onChange={() => handleColumnToggle(col.field_identifier)}
                  />
                }
                label={col.display_name}
              />
            ))}
          </FormGroup>
        </Box>
      ))}
    </Box>
  );

  // Filters section
  const renderFiltersSection = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>Filters</Typography>
      {filters.map((filter, index) => (
        <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Field"
            value={filter.column}
            onChange={(e) => updateFilter(index, 'column', e.target.value)}
            sx={{ mr: 1 }}
          />
          <TextField
            label="Operator"
            value={filter.operator}
            onChange={(e) => updateFilter(index, 'operator', e.target.value)}
            sx={{ mr: 1 }}
          />
          <TextField
            label="Value"
            value={filter.value}
            onChange={(e) => updateFilter(index, 'value', e.target.value)}
            sx={{ mr: 1 }}
          />
          <IconButton onClick={() => removeFilter(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button startIcon={<AddIcon />} onClick={() => addFilter('lead')} variant="outlined">
        Add Filter
      </Button>
    </Box>
  );

  // Sharing section
  const renderSharingSection = () => {
    // Filter out users that are already shared with
    const filteredAvailableUsers = availableUsers.filter(user => 
      !sharedUsers.some(shared => shared.id === user.id)
    );

    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>Share with Users</Typography>
        
        {/* User selector dropdown */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Add User</InputLabel>
          <Select
            value=""
            onChange={(e) => handleAddUser(e.target.value)}
            label="Add User"
          >
            {filteredAvailableUsers.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.name || user.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* List of shared users */}
        <Paper variant="outlined">
          <List>
            {sharedUsers.map((user, index) => (
              <React.Fragment key={user.id}>
                {index > 0 && <Divider />}
                <ListItem
                  secondaryAction={
                    <IconButton 
                      edge="end" 
                      aria-label="remove" 
                      onClick={() => handleRemoveUser(user.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={user.name || user.email}
                    secondary={
                      <Box sx={{ mt: 1 }}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_view}
                                onChange={() => handlePermissionChange(user.id, 'can_view')}
                                size="small"
                              />
                            }
                            label="View"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_edit}
                                onChange={() => handlePermissionChange(user.id, 'can_edit')}
                                size="small"
                              />
                            }
                            label="Edit"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_share}
                                onChange={() => handlePermissionChange(user.id, 'can_share')}
                                size="small"
                              />
                            }
                            label="Share"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_delete}
                                onChange={() => handlePermissionChange(user.id, 'can_delete')}
                                size="small"
                              />
                            }
                            label="Delete"
                          />
                        </FormGroup>
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
            {sharedUsers.length === 0 && (
              <ListItem>
                <ListItemText 
                  primary="No users added"
                  secondary="Select users from the dropdown above to share this view"
                />
              </ListItem>
            )}
          </List>
        </Paper>
      </Box>
    );
  };

  // --- Render the page ---
  return (
    <Box sx={{ p: 3 }}>
      {/* Top header with Back button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Button variant="outlined" onClick={handleCancel}>
          Back to Custom Views
        </Button>
        <Typography variant="h5" component="h1">
          Edit Custom View
        </Typography>
        <Box /> {/* Spacer */}
      </Box>
      {/* Main content divided into three sections */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          {renderGeneralInfoSection()}
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          {renderColumnsSection()}
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          {renderFiltersSection()}
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          {renderSharingSection()}
        </CardContent>
      </Card>
      {/* Save Button */}
      <Box sx={{ textAlign: 'right' }}>
        <Button variant="contained" onClick={handleSave} disabled={!viewName.trim()}>
          Save View
        </Button>
      </Box>
    </Box>
  );
};

export default CustomViewEditSinglePage;
