// src/components/CategorySidebar.js

import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Category as CategoryIcon } from '@mui/icons-material';

const CategorySidebar = ({ categories, selectedCategory, onSelectCategory }) => {
  const theme = useTheme();

  return (
    <List component="nav" sx={{ width: '100%' }}>
      {/* All Categories option */}
      <ListItem
        button
        selected={selectedCategory === 'All Categories'}
        onClick={() => onSelectCategory('All Categories')}
        sx={{
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        }}
      >
        <ListItemIcon>
          <CategoryIcon sx={{ color: theme.palette.text.primary }} />
        </ListItemIcon>
        <ListItemText primary="All Categories" sx={{ color: theme.palette.text.primary }} />
      </ListItem>

      {/* Integration Categories */}
      {categories.map((category) => (
        <ListItem
          button
          key={category.id}
          selected={selectedCategory === category.code}
          onClick={() => onSelectCategory(category.code)}
          sx={{
            '&.Mui-selected': {
              backgroundColor: theme.palette.action.selected,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          }}
        >
          <ListItemIcon>
            <CategoryIcon sx={{ color: theme.palette.text.primary }} />
          </ListItemIcon>
          <ListItemText 
            primary={category.name} 
            sx={{ color: theme.palette.text.primary }} 
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CategorySidebar;
