import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Stack,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon, Delete as DeleteIcon, Add as AddIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { api } from '../services/authService';
import MuiAlert from '@mui/material/Alert';
import ConfirmDialog from '../components/ConfirmDialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const AlertComponent = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const WorkflowOverviewPage = () => {
  const { workflowId } = useParams();
  const navigate = useNavigate();
  const [workflow, setWorkflow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedWorkflow, setEditedWorkflow] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteVariableDialogOpen, setDeleteVariableDialogOpen] = useState(false);
  const [deleteVariableLoading, setDeleteVariableLoading] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [variables, setVariables] = useState([]);
  const [leadFieldDefinitions, setLeadFieldDefinitions] = useState([]);
  const [intakeFields, setIntakeFields] = useState([]);
  const [editVariableDialog, setEditVariableDialog] = useState(false);
  const [editingVariable, setEditingVariable] = useState(null);
  const [editVariableLoading, setEditVariableLoading] = useState(false);

  useEffect(() => {
    const fetchWorkflow = async () => {
      try {
        const response = await api.get(`/reporting/workflows/${workflowId}/`);
        setWorkflow(response.data);
        setEditedWorkflow(response.data);

        // Set lead field definitions from campaign
        const campaign = response.data.campaign_details;
        setLeadFieldDefinitions(campaign?.lead_field_definitions || []);
        
        // Flatten intake fields from all sections
        const flattenedIntakeFields = campaign?.intake_sections?.flatMap(section => 
          section.intake_fields.map(field => ({
            id: field.id,
            name: field.name,
            api_name: field.api_name,
            field_type: field.field_type,
            section_name: section.name,
            required: field.required
          }))
        ) || [];
        
        setIntakeFields(flattenedIntakeFields);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflow();
  }, [workflowId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditedWorkflow(workflow);
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setEditedWorkflow(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const showNotification = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await api.put(`/reporting/workflows/${workflowId}/`, {
        name: editedWorkflow.name,
        description: editedWorkflow.description,
        webhook_url: editedWorkflow.webhook_url,
        api_key: editedWorkflow.api_key,
        active: editedWorkflow.active,
        workflow_type: workflow.workflow_type,
        platform: workflow.platform,
        account: workflow.account,
        campaign: workflow.campaign,
        funnel: workflow.funnel
      });
      setWorkflow(response.data);
      setIsEditing(false);
      showNotification('Workflow updated successfully');
    } catch (err) {
      showNotification(err.response?.data?.detail || 'Failed to update workflow', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditVariableClick = (variable) => {
    setEditingVariable({
      id: variable.id,
      variable_name: variable.variable_name,
      default_value: variable.default_value,
      is_required: variable.is_required,
      active: variable.active
    });
    setEditVariableDialog(true);
  };

  const handleEditVariableClose = () => {
    setEditVariableDialog(false);
    setEditingVariable(null);
  };

  const handleEditVariableChange = (field, value) => {
    setEditingVariable(prev => ({
      ...prev,
      [field]: field === 'default_value' ? (value.trim() === '' ? null : value) : value
    }));
  };

  const handleEditVariableSave = async () => {
    try {
      setEditVariableLoading(true);
      await api.patch(`/reporting/workflow-variables/${editingVariable.id}/`, {
        default_value: editingVariable.default_value,
        is_required: editingVariable.is_required,
        active: editingVariable.active
      });

      // Refresh workflow data
      const response = await api.get(`/reporting/workflows/${workflowId}/`);
      setWorkflow(response.data);
      showNotification('Variable updated successfully');
      handleEditVariableClose();
    } catch (err) {
      showNotification(err.response?.data?.detail || 'Failed to update variable', 'error');
    } finally {
      setEditVariableLoading(false);
    }
  };

  const handleDeleteVariableConfirm = async () => {
    try {
      setDeleteVariableLoading(true);
      await api.delete(`/reporting/workflow-variables/${selectedVariable.id}/`);
      const response = await api.get(`/reporting/workflows/${workflowId}/`);
      setWorkflow(response.data);
      showNotification('Variable deleted successfully', 'success');
      setDeleteVariableDialogOpen(false);
    } catch (err) {
      showNotification(err.response?.data?.detail || 'Failed to delete variable', 'error');
    } finally {
      setDeleteVariableLoading(false);
      setSelectedVariable(null);
    }
  };

  const handleDeleteVariableClick = (variable) => {
    setSelectedVariable(variable);
    setDeleteVariableDialogOpen(true);
  };

  const handleDeleteWorkflow = async () => {
    try {
      setDeleteLoading(true);
      await api.delete(`/reporting/workflows/${workflowId}/`);
      showNotification('Workflow deleted successfully', 'success');
      navigate('/crm/automations');
    } catch (err) {
      showNotification(err.response?.data?.detail || 'Failed to delete workflow', 'error');
      setDeleteDialogOpen(false);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleAddVariable = () => {
    setVariables((prevVars) => [
      ...prevVars,
      {
        variable_name: '',
        variable_type: 'string',  // default, can be changed
        is_required: false,
        mapping_model: '',        // no model selected initially
        mapping_key: '',
        mapping_key_id: '',
        default_value: '',
        active: true
      }
    ]);
  };

  const handleVariableChange = (index, field, value) => {
    const newVariables = [...variables];

    if (field === 'mapping_model') {
      // Reset fields when model changes
      newVariables[index] = {
        ...newVariables[index],
        mapping_model: value,
        mapping_key: '',
        mapping_key_id: '',
        variable_name: '',
        variable_type: value === 'custom' ? 'string' : ''
      };
    } else if (field === 'mapping_key') {
      if (value && newVariables[index].mapping_model === 'lead_field') {
        const selectedField = leadFieldDefinitions.find(def => def.id === parseInt(value));
        if (selectedField) {
          newVariables[index] = {
            ...newVariables[index],
            mapping_key: selectedField.api_name, // Use api_name instead of name
            mapping_key_id: selectedField.id,
            variable_name: selectedField.name,
            variable_type: mapFieldTypeToVariableType(selectedField.field_type, selectedField.api_name)
          };
        }
      } else if (value && newVariables[index].mapping_model === 'intake_field') {
        const selectedField = intakeFields.find(field => field.id === parseInt(value));
        if (selectedField) {
          newVariables[index] = {
            ...newVariables[index],
            mapping_key: selectedField.api_name, // Use api_name instead of name
            mapping_key_id: selectedField.id,
            variable_name: selectedField.name,
            variable_type: mapFieldTypeToVariableType(selectedField.field_type, selectedField.api_name)
          };
        }
      }
    } else if (field === 'variable_name' && newVariables[index].mapping_model === 'custom') {
      // For custom fields, format the mapping_key based on the variable_name
      const formattedMappingKey = value
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '')
        .replace(/\s+/g, '_');
      
      newVariables[index] = {
        ...newVariables[index],
        variable_name: value,
        mapping_key: formattedMappingKey
      };
    } else {
      newVariables[index] = {
        ...newVariables[index],
        [field]: field === 'default_value' ? (value.trim() === '' ? null : value) : value
      };
    }

    setVariables(newVariables);
  };

  const handleSaveVariable = async (variable) => {
    try {
      await api.post('/reporting/workflow-variables/', {
        reporting_workflow: workflowId,
        ...variable
      });
      
      // Refresh workflow data to get updated variables list
      const response = await api.get(`/reporting/workflows/${workflowId}/`);
      setWorkflow(response.data);
      setVariables([]);  // Clear the form
      showNotification('Variable added successfully', 'success');
    } catch (err) {
      showNotification(err.response?.data?.detail || 'Failed to add variable', 'error');
    }
  };

  const handleCancelVariable = (index) => {
    const newVariables = [...variables];
    newVariables.splice(index, 1);
    setVariables(newVariables);
  };

  // Add field type mapping function
  const mapFieldTypeToVariableType = (fieldType, apiName) => {
    // Special cases based on field name
    if (apiName === 'date_of_birth') {
      return 'date';
    }
    if (apiName === 'age') {
      return 'float';
    }

    // Default mappings
    const typeMapping = {
      'text': 'string',
      'number': 'float',
      'date': 'date',
      'boolean': 'boolean',
      'dropdown': 'string'
    };
    return typeMapping[fieldType] || 'string'; // default to string if unknown type
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {/* Add Back Button */}
      <Box sx={{ mb: 3 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/crm/automations/workflows/view')}
          variant="contained"
          sx={{ mb: 2 }}
        >
          Back to Workflows
        </Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" color="textPrimary">Workflow Overview</Typography>
        <Stack direction="row" spacing={2}>
          {!isEditing ? (
            <>
              <Button
                startIcon={<EditIcon />}
                variant="contained"
                onClick={handleEdit}
              >
                Edit Workflow
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                variant="contained"
                color="error"
                onClick={() => setDeleteDialogOpen(true)}
              >
                Delete Workflow
              </Button>
            </>
          ) : (
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={handleSave}
                color="primary"
              >
                Save Changes
              </Button>
              <Button
                startIcon={<CancelIcon />}
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>

      {/* Basic Information */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Workflow Name
            </Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={editedWorkflow?.name || ''}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            ) : (
              <Typography variant="body1">{workflow?.name}</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Status
            </Typography>
            <Chip 
              label={workflow?.active ? "Active" : "Inactive"}
              color={workflow?.active ? "success" : "default"}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Workflow Type
            </Typography>
            <Typography variant="body1">
              {workflow?.workflow_type_details?.name || "Not specified"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Reporting Platform
            </Typography>
            <Typography variant="body1">
              {workflow?.platform_details?.name || "Not specified"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              Description
            </Typography>
            {isEditing ? (
              <TextField
                fullWidth
                multiline
                rows={3}
                size="small"
                value={editedWorkflow?.description || ''}
                onChange={(e) => handleChange('description', e.target.value)}
              />
            ) : (
              <Typography variant="body1">
                {workflow?.description || "No description provided"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* Campaign Information */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Campaign Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Account
            </Typography>
            <Typography variant="body1">
              {workflow?.account_details?.name || workflow?.account_name || "Not specified"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Campaign
            </Typography>
            <Typography variant="body1">
              {workflow?.campaign_details?.name || workflow?.campaign_name || "Not specified"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" color="text.secondary">
              Funnel
            </Typography>
            <Typography variant="body1">
              {workflow?.funnel_details?.name || workflow?.funnel_name || "Not specified"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Integration Details */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Integration Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Webhook URL
            </Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                value={editedWorkflow?.webhook_url || ''}
                onChange={(e) => handleChange('webhook_url', e.target.value)}
              />
            ) : (
              <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                {workflow?.webhook_url || "Not configured"}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
              API Key
            </Typography>
            {isEditing ? (
              <TextField
                fullWidth
                size="small"
                type="password"
                value={editedWorkflow?.api_key || ''}
                onChange={(e) => handleChange('api_key', e.target.value)}
              />
            ) : (
              <Typography variant="body1">
                {workflow?.api_key ? "••••••••" : "Not configured"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* Variables */}
      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Workflow Variables</Typography>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={handleAddVariable}
          >
            Add Variable
          </Button>
        </Box>

        {/* New Variables Form */}
        {variables.map((variable, index) => (
          <Box key={index} sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Model</InputLabel>
                  <Select
                    value={variable.mapping_model || ''}
                    onChange={(e) => handleVariableChange(index, 'mapping_model', e.target.value)}
                    label="Model"
                  >
                    <MenuItem value="lead_field">Lead Field</MenuItem>
                    <MenuItem value="intake_field">Intake Field</MenuItem>
                    <MenuItem value="custom">Custom Field</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                {variable.mapping_model === 'custom' ? (
                  <TextField
                    fullWidth
                    size="small"
                    label="Tailor Variable Name"
                    value={variable.variable_name || ''}
                    onChange={(e) => {
                      const userInput = e.target.value;
                      const formattedName = userInput
                        .toLowerCase()
                        .replace(/[^a-z0-9\s]/g, '')
                        .replace(/\s+/g, '_');
                      
                      const newVariables = [...variables];
                      newVariables[index] = {
                        ...newVariables[index],
                        variable_name: userInput,        // Original user input
                        mapping_key: formattedName      // Formatted version
                      };
                      setVariables(newVariables);
                    }}
                  />
                ) : (
                  <FormControl fullWidth size="small">
                    <InputLabel>Field</InputLabel>
                    <Select
                      value={variable.mapping_key_id || ''}
                      onChange={(e) => handleVariableChange(index, 'mapping_key', e.target.value)}
                      label="Field"
                      disabled={!variable.mapping_model}
                    >
                      {variable.mapping_model === 'lead_field' && leadFieldDefinitions.map((field) => (
                        <MenuItem key={field.id} value={field.id}>
                          {field.name}
                        </MenuItem>
                      ))}
                      {variable.mapping_model === 'intake_field' && intakeFields.map((field) => (
                        <MenuItem key={field.id} value={field.id}>
                          {field.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  size="small"
                  label="Platform Variable Name"
                  value={variable.mapping_key || ''}
                  disabled
                  sx={{
                    "& .Mui-disabled": {
                      WebkitTextFillColor: (theme) => theme.palette.text.primary,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Default Value"
                  value={variable.default_value || ''}
                  onChange={(e) => handleVariableChange(index, 'default_value', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={variable.is_required}
                      onChange={(e) => handleVariableChange(index, 'is_required', e.target.checked)}
                    />
                  }
                  label="Required"
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveVariable(variable)}
                    sx={{ minWidth: '120px' }}
                  >
                    Save Variable
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleCancelVariable(index)}
                    startIcon={<CancelIcon />}
                    sx={{ minWidth: '120px' }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ))}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tailor Variable Name</TableCell>
                <TableCell>Variable Type</TableCell>
                <TableCell>Variable Model</TableCell>
                <TableCell>Platform Variable Name</TableCell>
                <TableCell>Required</TableCell>
                <TableCell>Default Value</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflow?.variables?.map((variable, index) => (
                <TableRow key={index}>
                  <TableCell>{variable.variable_name}</TableCell>
                  <TableCell>{variable.variable_type}</TableCell>
                  <TableCell>{variable.mapping_model}</TableCell>
                  <TableCell>{variable.mapping_key}</TableCell>
                  <TableCell>
                    <Chip 
                      size="small"
                      label={variable.is_required ? "Required" : "Optional"}
                      color={variable.is_required ? "primary" : "default"}
                    />
                  </TableCell>
                  <TableCell>{variable.default_value || "-"}</TableCell>
                  <TableCell>
                    <Chip 
                      size="small"
                      label={variable.active ? "Active" : "Inactive"}
                      color={variable.active ? "success" : "default"}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Tooltip title="Edit Variable">
                        <IconButton 
                          size="small" 
                          onClick={() => handleEditVariableClick(variable)}
                          sx={{ mr: 1 }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Variable">
                        <IconButton 
                          size="small" 
                          onClick={() => handleDeleteVariableClick(variable)}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Workflow"
        message="Are you sure you want to delete this workflow? This action cannot be undone."
        onConfirm={handleDeleteWorkflow}
        onCancel={() => setDeleteDialogOpen(false)}
        isLoading={deleteLoading}
        confirmText="Delete"
        confirmColor="error"
      />

      <ConfirmDialog
        open={deleteVariableDialogOpen}
        title="Delete Variable"
        message={`Are you sure you want to delete the variable "${selectedVariable?.variable_name}"? This action cannot be undone.`}
        onConfirm={handleDeleteVariableConfirm}
        onCancel={() => {
          setDeleteVariableDialogOpen(false);
          setSelectedVariable(null);
        }}
        isLoading={deleteVariableLoading}
        confirmText="Delete"
        confirmColor="error"
      />

      {/* Add Edit Variable Dialog */}
      <Dialog 
        open={editVariableDialog} 
        onClose={handleEditVariableClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Edit Variable: {editingVariable?.variable_name}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Default Value"
                  value={editingVariable?.default_value || ''}
                  onChange={(e) => handleEditVariableChange('default_value', e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editingVariable?.is_required || false}
                      onChange={(e) => handleEditVariableChange('is_required', e.target.checked)}
                    />
                  }
                  label="Required"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editingVariable?.active || false}
                      onChange={(e) => handleEditVariableChange('active', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleEditVariableClose}
            disabled={editVariableLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleEditVariableSave}
            variant="contained"
            disabled={editVariableLoading}
            startIcon={editVariableLoading ? <CircularProgress size={20} /> : null}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorkflowOverviewPage; 