// src/hooks/useNotificationPolling.js

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '../redux/notificationSlice';
import { selectCurrentToken } from '../redux/authSlice';

const useNotificationPolling = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    // Clear any existing interval when the effect runs
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    // Only set up polling if we have a valid token
    if (token) {
      // Initial fetch
      dispatch(fetchNotifications());

      // Set up the interval for subsequent fetches
      intervalIdRef.current = setInterval(() => {
        dispatch(fetchNotifications());
      }, 1800000); // 30 minutes
    }

    // Cleanup function
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [dispatch, token]); // Re-run when token changes
};

export default useNotificationPolling;
