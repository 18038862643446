// src/pages/IntegrationsPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  InputAdornment,
  useMediaQuery,
  Dialog,
} from '@mui/material';
import {
  Search as SearchIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import CategorySidebar from '../components/CategorySidebar';
import IntegrationsTable from '../components/IntegrationsTable';
import { api } from '../services/authService';

const IntegrationsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // State
  const [integrationCategories, setIntegrationCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [integrations, setIntegrations] = useState([]);

  // --------------------------------------------------------------------
  //  Fetch: Integration Categories
  // --------------------------------------------------------------------
  const fetchIntegrationCategories = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/integrations/integration-categories/active/');
      setIntegrationCategories(response.data);
    } catch (err) {
      setError(err.message || 'Failed to fetch integration categories.');
      console.error('Error fetching integration categories:', err);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------------------------------------
  //  Fetch: Integration Categories
  // --------------------------------------------------------------------
  const fetchCategories = async () => {
    try {
      const response = await api.get('/integrations/integration-categories/active/');
      setCategories(response.data);
    } catch (err) {
      console.error('Error fetching integration categories:', err);
      setError(err.message || 'Failed to fetch categories.');
    }
  };

  // --------------------------------------------------------------------
  //  Fetch: Integrations
  // --------------------------------------------------------------------
  const fetchIntegrations = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/integrations/integrations/active/', {
        params: {
          category: selectedCategory !== 'All Categories' 
            ? categories.find(c => c.code === selectedCategory)?.id 
            : undefined,
          is_active: true
        }
      });
      setIntegrations(response.data);
    } catch (err) {
      setError(err.message || 'Failed to fetch integrations.');
      console.error('Error fetching integrations:', err);
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------------------------------------
  //  useEffect: Load data on mount
  // --------------------------------------------------------------------
  useEffect(() => {
    fetchCategories();
    fetchIntegrationCategories();
  }, []);

  // --------------------------------------------------------------------
  //  useEffect: Load data when category changes
  // --------------------------------------------------------------------
  useEffect(() => {
    fetchIntegrations();
  }, [selectedCategory]);

  // --------------------------------------------------------------------
  //  Derived: Filter integrations by search
  // --------------------------------------------------------------------
  const filteredIntegrations = integrations.filter((integration) => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      integration.name.toLowerCase().includes(searchTerm) ||
      integration.description?.toLowerCase().includes(searchTerm) ||
      integration.category_name?.toLowerCase().includes(searchTerm)
    );
  });

  // --------------------------------------------------------------------
  //  Handlers
  // --------------------------------------------------------------------
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Sidebar */}
      {!isMobile ? (
        <Box
          sx={{
            width: 240,
            flexShrink: 0,
            backgroundColor: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <CategorySidebar
            categories={categories}
            selectedCategory={selectedCategory}
            onSelectCategory={setSelectedCategory}
          />
        </Box>
      ) : (
        <Box>
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: theme.palette.text.primary }} />
          </IconButton>
          <Dialog open={mobileOpen} onClose={handleDrawerToggle} fullScreen>
            <CategorySidebar
              categories={categories}
              selectedCategory={selectedCategory}
              onSelectCategory={setSelectedCategory}
            />
          </Dialog>
        </Box>
      )}

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">
            {selectedCategory === 'All Categories' 
              ? 'All Integrations' 
              : categories.find(c => c.code === selectedCategory)?.name || selectedCategory}
          </Typography>
        </Box>

        {/* Search */}
        <TextField
          fullWidth
          margin="normal"
          placeholder="Search integrations..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Content */}
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <IntegrationsTable data={filteredIntegrations} />
        )}
      </Box>
    </Box>
  );
};

export default IntegrationsPage;
