// src/pages/CampaignSettings.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Box,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { api } from '../services/authService';
import theme from '../theme/theme';
import IntakeManagement from './IntakeManagement';
import LeadFieldsConfiguration from '../pages/LeadFieldsConfiguration';
import moment from 'moment-timezone';

const CampaignSettings = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // State variables
  const [campaign, setCampaign] = useState(null);
  const [campaignModels, setCampaignModels] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [availableLeadFields, setAvailableLeadFields] = useState([]);
  const [selectedLeadFields, setSelectedLeadFields] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [errors, setErrors] = useState({});
  const [accountId, setAccountId] = useState(location.state?.accountId || null);
  const [timezones, setTimezones] = useState([]);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [cloneOptions, setCloneOptions] = useState({
    name: '',
    description: '',
    clone_campaign_access: false,
    clone_lead_field_mappings: false,
    clone_intake_sections: false,
    clone_default_funnel: false,
  });

  useEffect(() => {
    if (campaignId && accountId) {
      fetchCampaignDetails(campaignId);
      fetchCampaignModels();
    }
    // Fetch timezones from moment-timezone
    const tzNames = moment.tz.names();
    setTimezones(tzNames);
  }, [campaignId, accountId]);

  // Fetch campaign details
  const fetchCampaignDetails = async (campaignId) => {
    try {
      const response = await api.get(`/crm/campaigns/${campaignId}/?account_id=${accountId}`);
      setCampaign(response.data);
      if (response.data.campaign_model) {
        await fetchAvailableLeadFields(response.data.campaign_model.id);
        setSelectedLeadFields(
          response.data.lead_field_definitions
            ? response.data.lead_field_definitions.map((field) => field.api_name)
            : []
        );
      }
    } catch (error) {
      console.error('Error fetching campaign details:', error);
      showSnackbar('Error fetching campaign details', 'error');
    }
  };

  // Fetch available campaign models
  const fetchCampaignModels = async () => {
    try {
      const response = await api.get('/crm/campaign_models/');
      setCampaignModels(response.data);
    } catch (error) {
      console.error('Error fetching campaign models:', error);
    }
  };

  const fetchAvailableLeadFields = async (campaignModelId) => {
    try {
      const response = await api.get(`/crm/campaign_models/${campaignModelId}/lead_fields/`);
      setAvailableLeadFields(response.data);
    } catch (error) {
      console.error('Error fetching available lead fields:', error);
      showSnackbar('Error fetching available lead fields', 'error');
    }
  };

  // Handle form input change for campaign data
  const handleCampaignInputChange = (e) => {
    const { name, value } = e.target;
    setCampaign((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'campaign_model') {
      fetchAvailableLeadFields(value.id); // value is campaign_model_id
      setSelectedLeadFields([]); // Reset selected fields when campaign model changes
    }
  };

  // Function to update campaign details
  const handleUpdateCampaign = async () => {
    setErrors({});

    // Validate required fields
    const newErrors = {};
    if (!campaign.campaign_model) {
      newErrors.campaign_model = 'Campaign Model is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      showSnackbar('Please fix the errors in the form', 'error');
      return;
    }

    try {
      const updatedCampaign = {
        ...campaign,
        campaign_model_id: campaign.campaign_model.id, // Send only the ID
        account_id: accountId,
      };

      await api.put(`/crm/campaigns/${campaign.id}/`, updatedCampaign);
      showSnackbar('Campaign updated successfully', 'success');
    } catch (error) {
      console.error('Error updating campaign:', error);
      showSnackbar('Error updating campaign', 'error');
    }
  };

  // Function to handle delete button click (opens confirmation dialog)
  const handleOpenDeleteDialog = () => {
    setConfirmDeleteDialogOpen(true);
  };

  // Function to delete a campaign
  const handleConfirmDeleteCampaign = async () => {
    try {
      await api.delete(`/crm/campaigns/${campaign.id}/`);
      showSnackbar('Campaign deleted successfully', 'success');
      setConfirmDeleteDialogOpen(false);
      navigate('/crm/campaigns');
    } catch (error) {
      console.error('Error deleting campaign:', error);
      showSnackbar('Error deleting campaign', 'error');
    }
  };

  // Function to save Lead Field Configuration
  const handleSaveLeadFieldConfig = async () => {
    try {
      const leadFieldDefinitionIds = selectedLeadFields
        .map((apiName) => {
          const field = availableLeadFields.find((f) => f.api_name === apiName);
          return field ? field.id : null;
        })
        .filter((id) => id !== null);

      const updatedCampaign = {
        ...campaign,
        lead_field_definition_ids: leadFieldDefinitionIds,
        account_id: accountId,
      };

      await api.put(`/crm/campaigns/${campaign.id}/`, updatedCampaign);
      showSnackbar('Lead field configuration saved successfully', 'success');
    } catch (error) {
      console.error('Error saving lead field configuration:', error);
      showSnackbar('Error saving lead field configuration', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloneCampaign = async () => {
    try {
      const response = await api.post(`/crm/campaigns/${campaign.id}/clone/`, cloneOptions);
      showSnackbar(`Campaign "${response.data.campaign.name}" was successfully cloned`, 'success');
      setCloneDialogOpen(false);
      navigate('/crm/campaigns/', { state: { accountId } });
    } catch (error) {
      console.error('Error cloning campaign:', error);
      const errorMessage = error.response?.data?.error || 'Error cloning campaign';
      showSnackbar(errorMessage, 'error');
    }
  };

  const handleCloneInputChange = (e) => {
    const { name, value } = e.target;
    setCloneOptions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/crm/campaigns')}
          sx={{ color: theme.palette.text.primary }}
        >
          Back to Campaigns
        </Button>
        <Button
          variant="contained"
          onClick={() => setCloneDialogOpen(true)}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            ':hover': { backgroundColor: theme.palette.primary.dark },
          }}
        >
          Clone Campaign
        </Button>
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: theme.palette.text.primary,
          fontWeight: theme.typography.h5.fontWeight,
        }}
      >
        Campaign Settings
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="campaign settings tabs">
        <Tab label="General Information" />
        <Tab label="Leads Configuration" />
        <Tab label="Intake Management" />
      </Tabs>

      <Divider sx={{ my: 2, backgroundColor: theme.palette.text.secondary }} />

      {tabValue === 0 && campaign && (
        <>
          <TextField
            label="Account Name"
            value={campaign.account_name || ''}
            fullWidth
            disabled
            sx={{
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              label: { color: theme.palette.text.secondary },
            }}
          />

          <TextField
            label="Campaign Name"
            name="name"
            fullWidth
            value={campaign.name || ''}
            onChange={handleCampaignInputChange}
            sx={{
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              label: { color: theme.palette.text.secondary },
            }}
          />

          <TextField
            label="Campaign Description"
            name="description"
            fullWidth
            multiline
            rows={3}
            value={campaign.description || ''}
            onChange={handleCampaignInputChange}
            sx={{
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              label: { color: theme.palette.text.secondary },
            }}
          />

          {/* Fixed Status FormControl */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel sx={{ color: theme.palette.text.secondary }}>Status</InputLabel>
            <Select
              labelId="status-label"
              name="active"
              value={campaign.active ? 'active' : 'inactive'}
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
              onChange={(e) =>
                handleCampaignInputChange({ target: { name: 'active', value: e.target.value === 'active' } })
              }
              label="Status"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          {/* Fixed Start Date Field */}
          <TextField
            label="Start Date"
            name="start_date"
            type="date"
            fullWidth
            value={campaign.start_date || ''}
            onChange={handleCampaignInputChange}
            sx={{
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              label: { color: theme.palette.text.secondary },
            }}
            InputLabelProps={{
              shrink: true, // This ensures the label does not overlap
            }}
          />

          {/* Fixed End Date Field */}
          <TextField
            label="End Date"
            name="end_date"
            type="date"
            fullWidth
            value={campaign.end_date || ''}
            onChange={handleCampaignInputChange}
            sx={{
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              label: { color: theme.palette.text.secondary },
            }}
            InputLabelProps={{
              shrink: true, // This ensures the label does not overlap
            }}
          />

          {/* <TextField
            label="Data Retention Period (days)"
            name="data_retention_period"
            type="number"
            fullWidth
            value={campaign.data_retention_period || ''}
            onChange={handleCampaignInputChange}
            sx={{
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              label: { color: theme.palette.text.secondary },
            }}
          /> */}

          {/* Add this before the Campaign Model Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="timezone-label">Default Timezone</InputLabel>
            <Select
              labelId="timezone-label"
              id="timezone"
              name="timezone"
              value={campaign.timezone || 'UTC'}
              onChange={handleCampaignInputChange}
              label="Default Timezone"
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            >
              {timezones.map((tz) => (
                <MenuItem key={tz} value={tz}>
                  {tz}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Add the new appointment deduplication window field */}
          <TextField
            label="Appointment Deduplication Window (minutes)"
            name="appointment_deduplication_window"
            type="number"
            fullWidth
            value={campaign.appointment_deduplication_window || 60}
            onChange={handleCampaignInputChange}
            helperText="Buffer time in minutes to prevent overlapping appointments"
            InputProps={{
              inputProps: { min: 0 }
            }}
            sx={{
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              input: { color: theme.palette.text.primary },
              label: { color: theme.palette.text.secondary },
            }}
          />

          {/* Campaign Model Selection */}
          <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.campaign_model} required>
            <InputLabel id="campaign-model-label" required>
              Campaign Model
            </InputLabel>
            <Select
              labelId="campaign-model-label"
              id="campaign-model"
              name="campaign_model"
              value={campaign.campaign_model ? campaign.campaign_model.id : ''}
              onChange={(e) => {
                const selectedModel = campaignModels.find((model) => model.id === e.target.value);
                handleCampaignInputChange({ target: { name: 'campaign_model', value: selectedModel } });
              }}
              label="Campaign Model"
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            >
              {campaignModels.map((model) => (
                <MenuItem key={model.id} value={model.id}>
                  {model.name}
                </MenuItem>
              ))}
            </Select>
            {errors.campaign_model && (
              <FormHelperText>{errors.campaign_model}</FormHelperText>
            )}
          </FormControl>

          {/* Update and Delete buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button
              variant="contained"
              onClick={handleOpenDeleteDialog}
              sx={{
                backgroundColor: 'red',
                color: 'white',
                ':hover': {
                  backgroundColor: 'darkred',
                },
              }}
            >
              Delete Campaign
            </Button>

            <Button
              variant="contained"
              onClick={handleUpdateCampaign}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
                ':hover': { backgroundColor: theme.palette.primary.dark },
              }}
            >
              Update Campaign
            </Button>            
          </Box>
        </>
      )}

      {tabValue === 1 && campaign && (
        <LeadFieldsConfiguration
          campaignId={campaign.id}
          campaignModelId={campaign.campaign_model.id}
          selectedLeadFields={selectedLeadFields}
          setSelectedLeadFields={setSelectedLeadFields}
          accountId={accountId}
          handleSaveLeadFieldConfig={handleSaveLeadFieldConfig} // Pass the handler
        />
      )}

      {tabValue === 2 && campaign && (
        <>
          <IntakeManagement campaignId={campaignId} />
        </>
      )}

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Confirm Delete Dialog */}
      <Dialog open={confirmDeleteDialogOpen} onClose={() => setConfirmDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the campaign? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteCampaign} variant="contained" color="error">
            Delete Campaign
          </Button>
          <Button onClick={() => setConfirmDeleteDialogOpen(false)} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={cloneDialogOpen} 
        onClose={() => setCloneDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Clone Campaign</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, mt: 1 }}>
            <TextField
              label="New Campaign Name"
              name="name"
              value={cloneOptions.name}
              onChange={handleCloneInputChange}
              fullWidth
              placeholder={`Copy of ${campaign?.name || ''}`}
            />
            <TextField
              label="New Campaign Description"
              name="description"
              value={cloneOptions.description}
              onChange={handleCloneInputChange}
              fullWidth
              multiline
              rows={3}
              placeholder={campaign?.description || ''}
            />
          </Box>
          <Typography sx={{ mb: 2 }}>
            Select which components you would like to clone:
          </Typography>
          <FormControl component="fieldset">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cloneOptions.clone_campaign_access}
                    onChange={(e) =>
                      setCloneOptions({
                        ...cloneOptions,
                        clone_campaign_access: e.target.checked,
                      })
                    }
                  />
                }
                label="Campaign Access"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cloneOptions.clone_lead_field_mappings}
                    onChange={(e) =>
                      setCloneOptions({
                        ...cloneOptions,
                        clone_lead_field_mappings: e.target.checked,
                      })
                    }
                  />
                }
                label="Lead Field Mappings"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cloneOptions.clone_intake_sections}
                    onChange={(e) =>
                      setCloneOptions({
                        ...cloneOptions,
                        clone_intake_sections: e.target.checked,
                      })
                    }
                  />
                }
                label="Intake Sections"
              />
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCloneDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCloneCampaign} variant="contained" color="primary">
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CampaignSettings;
