// src/components/EditTaskDialog.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Box,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaskThunk } from '../redux/tasksSlice';
import { api } from '../services/authService';
import moment from 'moment-timezone';
import { DateTimePicker } from '@mui/x-date-pickers';

const EditTaskDialog = ({
  open,
  handleClose,
  task,
  accountId,
  campaignId,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const timezone = user?.timezone || 'UTC';

  // Lead selection state
  const [leadOptions, setLeadOptions] = useState([]);
  const [leadSearchQuery, setLeadSearchQuery] = useState('');
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [leadPage, setLeadPage] = useState(1);
  const [hasMoreLeads, setHasMoreLeads] = useState(true);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    due_date: null,
    priority: 'medium',
    status: 'pending',
    assigned_to: 'Unassigned',
    lead: '',
  });
  const [users, setUsers] = useState([]);

  // Function to fetch leads with search and pagination
  const fetchLeads = async (searchQuery = '', page = 1) => {
    if (!campaignId) return;
    
    try {
      setIsLoadingLeads(true);
      const response = await api.get(`/crm/leads/`, {
        params: {
          campaign_id: campaignId,
          search: searchQuery,
          page: page,
          page_size: 20
        }
      });

      const newLeads = response.data.results;
      setHasMoreLeads(!!response.data.next);

      if (page === 1) {
        // If we have a current lead that's not in the results, add it to prevent it from disappearing
        if (task.related_lead && !newLeads.find(lead => lead.id === task.related_lead.id)) {
          setLeadOptions([task.related_lead, ...newLeads]);
        } else {
          setLeadOptions(newLeads);
        }
      } else {
        setLeadOptions(prev => [...prev, ...newLeads]);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setIsLoadingLeads(false);
    }
  };

  // Initial setup effect - only set the current lead if it exists
  useEffect(() => {
    if (task.related_lead) {
      setLeadOptions([task.related_lead]);
    }
  }, [task.related_lead]);

  // Debounced search effect - only trigger if dropdown is opened
  useEffect(() => {
    if (!dropdownOpened) return;

    const timer = setTimeout(() => {
      if (leadSearchQuery !== '') {
        setLeadPage(1);
        fetchLeads(leadSearchQuery, 1);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [leadSearchQuery, campaignId, dropdownOpened]);

  useEffect(() => {
    if (task) {
      const dueDateLocal = task.due_date
        ? moment.utc(task.due_date).tz(timezone)
        : null;

      setFormData({
        title: task.title || '',
        description: task.description || '',
        due_date: dueDateLocal,
        priority: task.priority || 'medium',
        status: task.status || 'pending',
        assigned_to: task.assigned_to?.id || 'Unassigned',
        lead: task.related_lead?.id || '',
      });
    }
  }, [task, timezone]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get(
          `/crm/accounts/${accountId}/campaigns/users/?campaign_id=${campaignId}`
        );
        setUsers(response.data);

        if (
          task.assigned_to &&
          response.data.some((user) => user.id === task.assigned_to.id)
        ) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            assigned_to: task.assigned_to.id,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            assigned_to: 'Unassigned',
          }));
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (accountId && campaignId) {
      fetchUsers();
    }
  }, [accountId, campaignId, task.assigned_to]);

  const handleSubmit = () => {
    // Convert due_date from user's timezone to UTC using moment-timezone
    let dueDateUtc = null;
    if (formData.due_date) {
      dueDateUtc = moment
        .tz(formData.due_date, timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    const updatedTaskData = {
      ...formData,
      due_date: dueDateUtc,
      campaign: campaignId, // Include the campaign field
      assigned_to:
        formData.assigned_to === 'Unassigned' ? null : formData.assigned_to,
      related_lead_id: formData.lead || null,
    };

    dispatch(updateTaskThunk({ taskId: task.id, taskData: updatedTaskData }));
    handleClose();
  };

  if (!task) return null;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Task</DialogTitle>
      <DialogContent>
        {/* Title */}
        <TextField
          label="Title"
          fullWidth
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          margin="normal"
        />
        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          margin="normal"
        />
        {/* Due Date */}
        <DateTimePicker
          label="Due Date"
          value={formData.due_date}
          onChange={(newValue) =>
            setFormData({ ...formData, due_date: newValue })
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth margin="normal" />
          )}
        />
        {/* Lead Selection */}
        <Autocomplete
          fullWidth
          options={leadOptions}
          getOptionLabel={(option) => 
            `${option.first_name} ${option.last_name}${option.phone_number ? ` - ${option.phone_number}` : ''}`
          }
          value={leadOptions.find(lead => lead.id === formData.lead) || null}
          onChange={(_, newValue) => {
            setFormData({ ...formData, lead: newValue?.id || '' });
          }}
          onInputChange={(_, newInputValue) => {
            setLeadSearchQuery(newInputValue);
          }}
          onOpen={() => {
            setDropdownOpened(true);
            // Only fetch initial leads if we don't have any (except possibly the current lead)
            if (leadOptions.length <= 1) {
              fetchLeads('', 1);
            }
          }}
          onClose={() => {
            setDropdownOpened(false);
          }}
          filterOptions={(x) => x} // Disable client-side filtering
          loading={isLoadingLeads}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Lead (Optional)"
              margin="normal"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingLeads ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: (event) => {
              const listboxNode = event.currentTarget;
              if (
                listboxNode.scrollTop + listboxNode.clientHeight 
                === listboxNode.scrollHeight
                && hasMoreLeads 
                && !isLoadingLeads
              ) {
                const nextPage = leadPage + 1;
                setLeadPage(nextPage);
                fetchLeads(leadSearchQuery, nextPage);
              }
            },
          }}
        />
        {/* Priority */}
        <TextField
          label="Priority"
          select
          fullWidth
          value={formData.priority}
          onChange={(e) =>
            setFormData({ ...formData, priority: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="low">Low</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="high">High</MenuItem>
        </TextField>
        {/* Status */}
        <TextField
          label="Status"
          select
          fullWidth
          value={formData.status}
          onChange={(e) =>
            setFormData({ ...formData, status: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
        </TextField>
        {/* Assigned To */}
        <TextField
          label="Assigned To"
          select
          fullWidth
          value={formData.assigned_to}
          onChange={(e) =>
            setFormData({ ...formData, assigned_to: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="Unassigned">Unassigned</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </MenuItem>
          ))}
        </TextField>
        {/* Buttons */}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditTaskDialog;