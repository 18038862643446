import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Divider,
  FormHelperText,
  Chip,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  IconButton,
  Tooltip
} from '@mui/material';
import { api } from '../services/authService';
import { Add as AddIcon, Lock as LockIcon, Delete as DeleteIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const steps = ['Select Workflow Type', 'Configure Workflow', 'Set Variables'];

const CreateWorkflowPage = () => {
  const { platformId } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [platform, setPlatform] = useState(null);
  const [workflowTypes, setWorkflowTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Form state
  const [workflowData, setWorkflowData] = useState({
    name: '',
    workflow_type: '',
    platform: platformId,
    webhook_url: '',
    api_key: '',
    description: '',
    active: true,
    account: null,
    campaign: null,
    funnel: null,
  });

  const [variables, setVariables] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [funnels, setFunnels] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState(false);
  const [funnelsLoading, setFunnelsLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignType, setCampaignType] = useState(null);
  const [leadFieldDefinitions, setLeadFieldDefinitions] = useState([]);
  const [intakeFields, setIntakeFields] = useState([]);

  // Predefined lead model fields
  const baseLeadFields = [
    { name: 'first_name', label: 'First Name', required: true },
    { name: 'last_name', label: 'Last Name', required: true },
    { name: 'email', label: 'Email', required: true },
    { name: 'phone_number', label: 'Phone Number', required: true },
    { name: 'channel', label: 'Channel', required: true },
    { name: 'source', label: 'Source', required: true },
  ];

  const d2cLeadFields = [
    { name: 'date_of_birth', label: 'Date of Birth', required: false },
    { name: 'age', label: 'Age', required: false },
    { name: 'gender', label: 'Gender', required: false },
    { name: 'marital_status', label: 'Marital Status', required: false },
  ];

  const b2bLeadFields = [
    { name: 'company', label: 'Company', required: true },
    { name: 'job_title', label: 'Job Title', required: true },
    { name: 'industry', label: 'Industry', required: true },
    { name: 'company_size', label: 'Company Size', required: false },
    { name: 'annual_revenue', label: 'Annual Revenue', required: false },
  ];

  // Step validation state
  const [stepValidation, setStepValidation] = useState({
    0: false, // Workflow Type step
    1: false, // Configure Workflow step
    2: false  // Variables step
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const showNotification = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [platformRes, typesRes] = await Promise.all([
          api.get(`/reporting/platforms/${platformId}/`),
          api.get('/reporting/workflow-types/'),
        ]);
        setPlatform(platformRes.data);
        setWorkflowTypes(typesRes.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [platformId]);

  // Fetch accounts
  useEffect(() => {
    const fetchAccounts = async () => {
      setAccountsLoading(true);
      try {
        const response = await api.get('/crm/accounts/');
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        setError('Failed to fetch accounts');
      } finally {
        setAccountsLoading(false);
      }
    };
    fetchAccounts();
  }, []);

  // Fetch campaigns when account is selected
  useEffect(() => {
    const fetchCampaigns = async () => {
      if (workflowData.account) {
        setCampaignsLoading(true);
        try {
          const response = await api.get(`/crm/campaigns/?account_id=${workflowData.account}`);
          setCampaigns(response.data);
        } catch (error) {
          console.error('Error fetching campaigns:', error);
          setError('Failed to fetch campaigns');
        } finally {
          setCampaignsLoading(false);
        }
      } else {
        setCampaigns([]);
        setWorkflowData(prev => ({ ...prev, campaign: null, funnel: null }));
      }
    };
    fetchCampaigns();
  }, [workflowData.account]);

  // Fetch funnels when campaign is selected
  useEffect(() => {
    const fetchFunnels = async () => {
      if (workflowData.campaign) {
        setFunnelsLoading(true);
        try {
          const response = await api.get(`/crm/funnels/?campaign_id=${workflowData.campaign}`);
          setFunnels(response.data);
        } catch (error) {
          console.error('Error fetching funnels:', error);
          setError('Failed to fetch funnels');
        } finally {
          setFunnelsLoading(false);
        }
      } else {
        setFunnels([]);
        setWorkflowData(prev => ({ ...prev, funnel: null }));
      }
    };
    fetchFunnels();
  }, [workflowData.campaign]);

  // Update handleCampaignSelect to get both lead field definitions and intake fields
  const handleCampaignSelect = async (campaignId) => {
    const campaign = campaigns.find(c => c.id === campaignId);
    setSelectedCampaign(campaign);
    setCampaignType(campaign?.campaign_model?.name?.toLowerCase() || null);
    
    // Set lead field definitions from campaign
    setLeadFieldDefinitions(campaign?.lead_field_definitions || []);
    
    // Flatten intake fields from all sections with explicit field mapping
    const flattenedIntakeFields = campaign?.intake_sections?.flatMap(section => 
      section.intake_fields.map(field => ({
        id: field.id,
        name: field.name,
        api_name: field.api_name,
        field_type: field.field_type,
        section_name: section.name,
        required: field.required
      }))
    ) || [];
    
    setIntakeFields(flattenedIntakeFields);
    
    // Update workflow data
    setWorkflowData(prev => ({
      ...prev,
      campaign: campaignId
    }));
  };

  // Update handleInputChange for campaign selection
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'campaign') {
      handleCampaignSelect(value);
    } else {
      setWorkflowData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // Handle workflow type selection
  const handleWorkflowTypeSelect = (typeId) => {
    setWorkflowData(prev => ({
      ...prev,
      workflow_type: typeId
    }));
  };

  // Add field type mapping function
  const mapFieldTypeToVariableType = (fieldType) => {
    const typeMapping = {
      'text': 'string',
      'number': 'float',
      'date': 'date',
      'boolean': 'boolean',
      'dropdown': 'string'
    };
    return typeMapping[fieldType] || 'string'; // default to string if unknown type
  };

  // Handle variable changes
  const handleVariableChange = (index, field, value) => {
    const newVariables = [...variables];
    
    if (field === 'mapping_model') {
      // Reset related fields when changing model
      newVariables[index] = {
        ...newVariables[index],
        [field]: value,
        mapping_key: '',
        variable_name: '',
        variable_type: value === 'custom' ? 'string' : ''
      };
    } else if (field === 'mapping_key') {
      if (value && newVariables[index].mapping_model === 'lead_field') {
        // Handle lead field selection
        const selectedField = leadFieldDefinitions.find(def => def.id === value);
        if (selectedField) {
          newVariables[index] = {
            ...newVariables[index],
            mapping_key: selectedField.id,
            variable_name: selectedField.api_name,
            variable_type: mapFieldTypeToVariableType(selectedField.field_type)
          };
        }
      } else if (value && newVariables[index].mapping_model === 'intake_field') {
        // Handle intake field selection
        const selectedField = intakeFields.find(field => field.id === parseInt(value));
        if (selectedField) {
          newVariables[index] = {
            ...newVariables[index],
            mapping_key: selectedField.id,
            variable_name: selectedField.api_name,
            variable_type: mapFieldTypeToVariableType(selectedField.field_type)
          };
        }
      } else if (newVariables[index].mapping_model === 'custom') {
        // Handle custom field input
        const formattedName = value
          .toLowerCase()
          .replace(/[^a-z0-9\s]/g, '')
          .replace(/\s+/g, '_');
        
        newVariables[index] = {
          ...newVariables[index],
          mapping_key: value,
          variable_name: formattedName  // Save the formatted name as variable_name
        };
      }
    } else if (field === 'variable_name' && newVariables[index].mapping_model === 'custom') {
      // When updating variable_name for custom fields, format it
      const formattedName = value
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '')
        .replace(/\s+/g, '_');
      
      newVariables[index] = {
        ...newVariables[index],
        mapping_key: value,
        variable_name: formattedName
      };
    } else {
      newVariables[index] = {
        ...newVariables[index],
        [field]: value
      };
    }
    
    setVariables(newVariables);
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      // Create the workflow first
      const workflowRes = await api.post('/reporting/workflows/', {
        name: workflowData.name,
        description: workflowData.description,
        workflow_type: workflowData.workflow_type,
        platform: platform.id,
        webhook_url: workflowData.webhook_url,
        api_key: workflowData.api_key,
        account: workflowData.account,
        campaign: workflowData.campaign,
        funnel: workflowData.funnel,
        active: true
      });

      const workflow = workflowRes.data;
      showNotification('Workflow created successfully');

      try {
        // Create variables
        await Promise.all(variables.map(variable => {
          let variableName = variable.variable_name;
          let mappingKey = variable.mapping_key;
          
          if (variable.mapping_model === 'custom') {
            // For custom fields, format the mapping_key to be URL-safe
            mappingKey = variable.variable_name
              .toLowerCase()
              .replace(/[^a-z0-9\s]/g, '')
              .replace(/\s+/g, '_');
          } else if (variable.mapping_model === 'intake_field') {
            // For intake fields, find the selected field
            const selectedField = intakeFields.find(field => field.id === parseInt(variable.mapping_key));
            if (selectedField) {
              // Use the original name as variable_name and format api_name as mapping_key
              variableName = selectedField.name;
              mappingKey = selectedField.api_name.toLowerCase().replace(/\s+/g, '_');
            }
          } else if (variable.mapping_model === 'lead_field') {
            // For lead fields, find the selected field
            const selectedField = leadFieldDefinitions.find(field => field.id === parseInt(variable.mapping_key));
            if (selectedField) {
              // Use the original name as variable_name and format api_name as mapping_key
              variableName = selectedField.name;
              mappingKey = selectedField.api_name.toLowerCase().replace(/\s+/g, '_');
            }
          }

          return api.post('/reporting/workflow-variables/', {
            reporting_workflow: workflow.id,
            variable_name: variableName,
            variable_type: variable.variable_type,
            is_required: variable.is_required || false,
            mapping_model: variable.mapping_model,
            mapping_key: mappingKey,
            default_value: variable.default_value || null,
            active: variable.active !== false
          });
        }));

        // Add predefined system fields as variables
        const systemFields = getPredefinedFields();
        await Promise.all(systemFields.map(field => 
          api.post('/reporting/workflow-variables/', {
            reporting_workflow: workflow.id,
            variable_name: field.name,
            variable_type: field.variable_type,
            is_required: field.required || false,
            mapping_model: field.model,
            mapping_key: field.name.toLowerCase().replace(/\s+/g, '_'),
            default_value: null,
            active: true
          })
        ));

        showNotification('Variables created successfully', 'success');
      } catch (err) {
        showNotification('Failed to create some variables', 'error');
      }

      // Navigate regardless of variable creation success
      navigate(`/crm/automations/workflows/${workflow.id}`);
    } catch (err) {
      showNotification(err.response?.data?.detail || 'Failed to create workflow', 'error');
    }
  };

  // Combined predefined fields based on campaign type
  const getPredefinedFields = () => {
    let fields = baseLeadFields.map(field => ({
      ...field,
      model: 'lead',
      mapping_model: 'lead',
      variable_type: 'string',
      is_system: true,
    }));

    if (campaignType?.includes('d2c')) {
      fields = [
        ...fields,
        ...d2cLeadFields.map(field => ({
          ...field,
          model: 'd2c_lead',
          mapping_model: 'd2c_lead',
          variable_type: 'string',
          is_system: true,
        }))
      ];
    } else if (campaignType?.includes('b2b')) {
      fields = [
        ...fields,
        ...b2bLeadFields.map(field => ({
          ...field,
          model: 'b2b_lead',
          mapping_model: 'b2b_lead',
          variable_type: 'string',
          is_system: true,
        }))
      ];
    }

    return fields;
  };

  // Add function to handle row deletion
  const handleDeleteVariable = (indexToDelete) => {
    setVariables(variables.filter((_, index) => index !== indexToDelete));
  };

  // Step content
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            {workflowTypes.map((type) => (
              <Grid item xs={12} md={4} key={type.id}>
                <Paper
                  elevation={workflowData.workflow_type === type.id ? 3 : 1}
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                    border: theme => 
                      workflowData.workflow_type === type.id 
                        ? `2px solid ${theme.palette.primary.main}`
                        : '2px solid transparent',
                  }}
                  onClick={() => handleWorkflowTypeSelect(type.id)}
                >
                  <Typography variant="h6">{type.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {type.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        );

      case 1:
        return (
          <Grid container spacing={3}>
            {/* Account Selection */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Account</InputLabel>
                <Select
                  value={workflowData.account || ''}
                  onChange={handleInputChange}
                  name="account"
                  disabled={accountsLoading}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </Select>
                {accountsLoading && (
                  <FormHelperText>Loading accounts...</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Campaign Selection */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Campaign</InputLabel>
                <Select
                  value={workflowData.campaign || ''}
                  onChange={handleInputChange}
                  name="campaign"
                  disabled={!workflowData.account || campaignsLoading}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {campaigns.map((campaign) => (
                    <MenuItem key={campaign.id} value={campaign.id}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                </Select>
                {campaignsLoading && (
                  <FormHelperText>Loading campaigns...</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Funnel Selection */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Funnel</InputLabel>
                <Select
                  value={workflowData.funnel || ''}
                  onChange={handleInputChange}
                  name="funnel"
                  disabled={!workflowData.campaign || funnelsLoading}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {funnels.map((funnel) => (
                    <MenuItem key={funnel.id} value={funnel.id}>
                      {funnel.name}
                    </MenuItem>
                  ))}
                </Select>
                {funnelsLoading && (
                  <FormHelperText>Loading funnels...</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>

            {/* Existing workflow configuration fields */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Workflow Name"
                name="name"
                value={workflowData.name}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={workflowData.description}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Webhook URL"
                name="webhook_url"
                value={workflowData.webhook_url}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="API Key"
                name="api_key"
                value={workflowData.api_key}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Workflow Variables</Typography>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => setVariables([...variables, {}])}
              >
                Add Custom Variable
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Variable Model</TableCell>
                    <TableCell>Tailor Variable Name</TableCell>
                    <TableCell>Platform Variable Name</TableCell>
                    <TableCell>Variable Type</TableCell>
                    <TableCell>Required</TableCell>
                    <TableCell>Default Value</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Predefined System Fields */}
                  {getPredefinedFields().map((field, index) => (
                    <TableRow key={`system-${index}`}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LockIcon sx={{ fontSize: 16, mr: 1 }} color="disabled" />
                          {field.model}
                        </Box>
                      </TableCell>
                      <TableCell>{field.label}</TableCell>
                      <TableCell>{field.name}</TableCell>
                      <TableCell>{field.variable_type}</TableCell>
                      <TableCell>
                        <Chip 
                          size="small"
                          label={field.required ? "Required" : "Optional"}
                          color={field.required ? "primary" : "default"}
                        />
                      </TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        <Chip 
                          size="small"
                          label="Active"
                          color="success"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteVariable(index)}
                          color="error"
                          title="Delete variable"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* Custom Variables */}
                  {variables.map((variable, index) => (
                    <TableRow key={`custom-${index}`}>
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <Select
                            value={variable.mapping_model || ''}
                            onChange={(e) => handleVariableChange(index, 'mapping_model', e.target.value)}
                          >
                            <MenuItem value="lead_field">Lead Field</MenuItem>
                            <MenuItem value="intake_field">Intake Field</MenuItem>
                            <MenuItem value="custom">Custom Field</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {variable.mapping_model === 'lead_field' ? (
                          <FormControl fullWidth size="small">
                            <Select
                              value={variable.mapping_key || ''}
                              onChange={(e) => handleVariableChange(index, 'mapping_key', e.target.value)}
                            >
                              {leadFieldDefinitions.map((field) => (
                                <MenuItem key={field.id} value={field.id}>
                                  {field.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : variable.mapping_model === 'intake_field' ? (
                          <FormControl fullWidth size="small">
                            <Select
                              value={variable.mapping_key || ''}
                              onChange={(e) => handleVariableChange(index, 'mapping_key', e.target.value)}
                            >
                              {intakeFields.map((field) => (
                                <MenuItem key={field.id} value={field.id}>
                                  {`${field.section_name} - ${field.name}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : variable.mapping_model === 'custom' ? (
                          <TextField
                            size="small"
                            fullWidth
                            label="Tailor Variable Name"
                            value={variable.variable_name || ''}
                            onChange={(e) => {
                              const userInput = e.target.value;
                              const formattedName = userInput
                                .toLowerCase()
                                .replace(/[^a-z0-9\s]/g, '')
                                .replace(/\s+/g, '_');
                              
                              const newVariables = [...variables];
                              newVariables[index] = {
                                ...newVariables[index],
                                variable_name: userInput,
                                mapping_key: formattedName
                              };
                              setVariables(newVariables);
                            }}
                          />
                        ) : (
                          <TextField
                            size="small"
                            fullWidth
                            disabled
                            value=""
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          value={
                            variable.mapping_model === 'custom'
                              ? (variable.variable_name || '')
                                  .toLowerCase()
                                  .replace(/[^a-z0-9\s]/g, '')
                                  .replace(/\s+/g, '_')
                              : variable.variable_name || ''
                          }
                          onChange={(e) => handleVariableChange(index, 'variable_name', e.target.value)}
                          disabled={true}
                          sx={{
                            "& .Mui-disabled": {
                              WebkitTextFillColor: theme => theme.palette.text.primary,
                              color: theme => theme.palette.text.primary
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth size="small">
                          <Select
                            value={variable.variable_type || ''}
                            onChange={(e) => handleVariableChange(index, 'variable_type', e.target.value)}
                            disabled={variable.mapping_model === 'lead_field' || variable.mapping_model === 'intake_field'}
                            sx={{
                              "& .Mui-disabled": {
                                WebkitTextFillColor: theme => theme.palette.text.primary,
                                color: theme => theme.palette.text.primary,
                                "& .MuiSelect-select": {
                                  color: theme => theme.palette.text.primary
                                }
                              }
                            }}
                          >
                            <MenuItem value="string">String</MenuItem>
                            <MenuItem value="integer">Integer</MenuItem>
                            <MenuItem value="float">Float</MenuItem>
                            <MenuItem value="boolean">Boolean</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="datetime">DateTime</MenuItem>
                            <MenuItem value="json">JSON</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={variable.is_required || false}
                          onChange={(e) => handleVariableChange(index, 'is_required', e.target.checked)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          value={variable.default_value || ''}
                          onChange={(e) => handleVariableChange(index, 'default_value', e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={variable.active !== false}
                          onChange={(e) => handleVariableChange(index, 'active', e.target.checked)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteVariable(index)}
                          color="error"
                          title="Delete variable"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );

      default:
        return 'Unknown step';
    }
  };

  // Validate workflow type step
  useEffect(() => {
    setStepValidation(prev => ({
      ...prev,
      0: !!workflowData.workflow_type
    }));
  }, [workflowData.workflow_type]);

  // Validate configure workflow step
  useEffect(() => {
    const isValid = !!(
      workflowData.account &&
      workflowData.campaign &&
      workflowData.funnel &&
      workflowData.name  // Only these fields are required
    );
    setStepValidation(prev => ({
      ...prev,
      1: isValid
    }));
  }, [workflowData]);

  // Validate variables step
  useEffect(() => {
    const isValid = variables.every(variable => {
      if (!variable.mapping_model || !variable.mapping_key) {
        return false;
      }
      if (variable.is_required && !variable.default_value) {
        return false;
      }
      return true;
    });
    setStepValidation(prev => ({
      ...prev,
      2: isValid
    }));
  }, [variables]);

  // Helper function to get step error message
  const getStepErrorMessage = (step) => {
    switch (step) {
      case 0:
        return !workflowData.workflow_type ? 'Please select a workflow type' : '';
      case 1:
        return !stepValidation[1] ? 'Please fill out all required fields' : '';
      case 2:
        return !stepValidation[2] ? 'Please complete all variable configurations' : '';
      default:
        return '';
    }
  };

  if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}><CircularProgress /></Box>;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ p: 3 }}>
      {/* Add Back Button */}
      <Box sx={{ mb: 3 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/crm/automations')}
          variant="contained"
          sx={{ mb: 2 }}
        >
          Back to Platforms
        </Button>
      </Box>

      <Typography variant="h4" color="textPrimary" gutterBottom>
        Create Workflow
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper sx={{ p: 3 }}>
        {getStepContent(activeStep)}

        {/* Show error message if step is not valid */}
        {!stepValidation[activeStep] && (
          <Typography color="error" sx={{ mt: 2 }}>
            {getStepErrorMessage(activeStep)}
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            disabled={activeStep === 0}
            onClick={() => setActiveStep((prev) => prev - 1)}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (activeStep === steps.length - 1) {
                handleSubmit();
              } else {
                setActiveStep((prev) => prev + 1);
              }
            }}
            disabled={!stepValidation[activeStep]}
          >
            {activeStep === steps.length - 1 ? 'Create Workflow' : 'Next'}
          </Button>
        </Box>
      </Paper>

      {/* Add Snackbar at the end of the component */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default CreateWorkflowPage; 