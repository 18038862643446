// src/components/CreateTaskDialog.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, MenuItem, Box, CircularProgress, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskThunk } from '../redux/tasksSlice';
import { api } from '../services/authService';
import moment from 'moment-timezone'; // Import moment-timezone
import { DateTimePicker } from '@mui/x-date-pickers'; // Updated import
import PropTypes from 'prop-types';

const CreateTaskDialog = ({ open, handleClose, accountId, campaignId, leadId, leadName, onSuccess = () => {}, onError = () => {}  }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const timezone = user?.timezone || 'UTC'; // Get user's timezone

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    due_date: null,
    priority: 'medium',
    status: 'pending',
    assigned_to: 'Unassigned',
    lead: leadId || '',
    task_type: 'general',  // Add default task type
  });

  const [users, setUsers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  const [leadOptions, setLeadOptions] = useState([]);
  const [leadSearchQuery, setLeadSearchQuery] = useState('');
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [leadPage, setLeadPage] = useState(1);
  const [hasMoreLeads, setHasMoreLeads] = useState(true);

  const taskTypes = [
    { value: 'general', label: 'General Task' },
    { value: 'follow_up', label: 'Follow Up' },
    { value: 'qualification', label: 'Qualification' },
    { value: 'reactivation', label: 'Reactivation' },
  ];

  useEffect(() => {
    // Fetch users who have access to the campaign
    const fetchUsers = async () => {
      try {
        const response = await api.get(
          `/crm/accounts/${accountId}/campaigns/users/?campaign_id=${campaignId}`
        );
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (accountId && campaignId) {
      fetchUsers();
    }
  }, [accountId, campaignId]);

  // Function to fetch leads with search and pagination
  const fetchLeads = async (searchQuery = '', page = 1) => {
    if (!campaignId) return;
    
    try {
      setIsLoadingLeads(true);
      const response = await api.get(`/crm/leads/`, {
        params: {
          campaign_id: campaignId,
          search: searchQuery,
          page: page,
          page_size: 20
        }
      });

      const newLeads = response.data.results;
      setHasMoreLeads(!!response.data.next);

      if (page === 1) {
        setLeadOptions(newLeads);
      } else {
        setLeadOptions(prev => [...prev, ...newLeads]);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
      onError('Failed to fetch leads');
    } finally {
      setIsLoadingLeads(false);
    }
  };

  // Debounced search effect
  useEffect(() => {
    const timer = setTimeout(() => {
      if (leadSearchQuery !== '') {
        setLeadPage(1);
        fetchLeads(leadSearchQuery, 1);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [leadSearchQuery, campaignId]);

  // Initial load effect
  useEffect(() => {
    if (campaignId && !leadId) {
      fetchLeads('', 1);
    }
  }, [campaignId]);

  const handleSubmit = async () => {
    // Convert due_date from user's timezone to UTC using moment-timezone
    let dueDateUtc = null;
    if (formData.due_date) {
      dueDateUtc = moment
        .tz(formData.due_date, timezone)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]');
    }

    const taskData = {
      ...formData,
      due_date: dueDateUtc,
      campaign: campaignId,
      related_lead_id: formData.lead || null,
      assigned_to: formData.assigned_to === 'Unassigned' ? null : formData.assigned_to,
    };

    setLoading(true);
    try {
      await dispatch(createTaskThunk(taskData)).unwrap();
      onSuccess('Task created successfully!');
      handleDialogClose();
    } catch (error) {
      console.error('Error creating task:', error);
      onError('Failed to create task.');
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setFormData({
      title: '',
      description: '',
      due_date: null,
      priority: 'medium',
      status: 'pending',
      assigned_to: 'Unassigned',
      lead: leadId || '',
      task_type: 'general',
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
      <DialogTitle>Create Task</DialogTitle>
      <DialogContent>
        {/* Title */}
        <TextField
          label="Title"
          fullWidth
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          margin="normal"
        />
        {/* Task Type */}
        <TextField
          label="Task Type"
          select
          fullWidth
          value={formData.task_type}
          onChange={(e) => setFormData({ ...formData, task_type: e.target.value })}
          margin="normal"
        >
          {taskTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
        {/* Description */}
        <TextField
          label="Description"
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          margin="normal"
        />
        {/* Due Date */}
        <DateTimePicker
          label="Due Date"
          value={formData.due_date}
          onChange={(newValue) => setFormData({ ...formData, due_date: newValue })}
          renderInput={(params) => (
            <TextField {...params} fullWidth margin="normal" />
          )}
        />
        {/* Lead Selection */}
        {leadId ? (
          <TextField
            label="Lead"
            fullWidth
            value={leadName}
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <Autocomplete
            fullWidth
            options={leadOptions}
            getOptionLabel={(option) => 
              `${option.first_name} ${option.last_name}${option.phone_number ? ` - ${option.phone_number}` : ''}`
            }
            value={leadOptions.find(lead => lead.id === formData.lead) || null}
            onChange={(_, newValue) => {
              setFormData({ ...formData, lead: newValue?.id || '' });
            }}
            onInputChange={(_, newInputValue) => {
              setLeadSearchQuery(newInputValue);
            }}
            filterOptions={(x) => x} // Disable client-side filtering
            loading={isLoadingLeads}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Lead (Optional)"
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingLeads ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: (event) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight 
                  === listboxNode.scrollHeight
                  && hasMoreLeads 
                  && !isLoadingLeads
                ) {
                  const nextPage = leadPage + 1;
                  setLeadPage(nextPage);
                  fetchLeads(leadSearchQuery, nextPage);
                }
              },
            }}
          />
        )}
        {/* Priority */}
        <TextField
          label="Priority"
          select
          fullWidth
          value={formData.priority}
          onChange={(e) =>
            setFormData({ ...formData, priority: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="low">Low</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="high">High</MenuItem>
        </TextField>
        {/* Status */}
        <TextField
          label="Status"
          select
          fullWidth
          value={formData.status}
          onChange={(e) =>
            setFormData({ ...formData, status: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
        </TextField>
        {/* Assigned To */}
        <TextField
          label="Assigned To"
          select
          fullWidth
          value={formData.assigned_to}
          onChange={(e) =>
            setFormData({ ...formData, assigned_to: e.target.value })
          }
          margin="normal"
        >
          <MenuItem value="Unassigned">Unassigned</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </MenuItem>
          ))}
        </TextField>
        {/* Buttons */}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Create Task
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

CreateTaskDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  leadId: PropTypes.string,
  leadName: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default CreateTaskDialog;
