import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  CardActionArea,
} from '@mui/material';
import { Phone, Email, Chat } from '@mui/icons-material';

const PlatformCard = ({ platform }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/crm/automations/create/${platform.id}`);
  };

  // Helper function to get the appropriate icon based on category
  const getCategoryIcon = (categoryCode) => {
    switch (categoryCode?.toLowerCase()) {
      case 'phone':
        return <Phone />;
      case 'email':
        return <Email />;
      case 'chat':
        return <Chat />;
      default:
        return null;
    }
  };

  return (
    <Card 
      elevation={3}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'scale(1.02)',
          cursor: 'pointer',
        },
      }}
    >
      <CardActionArea 
        sx={{ height: '100%' }}
        onClick={handleCardClick}
      >
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" component="div">
              {platform.name}
            </Typography>
            <Chip
              label={platform.active ? 'Active' : 'Inactive'}
              color={platform.active ? 'success' : 'default'}
              size="small"
            />
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {getCategoryIcon(platform.platform_category?.code)}
            <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
              {platform.platform_category?.name || 'Uncategorized'}
            </Typography>
          </Box>

          <Typography variant="body2" color="text.secondary">
            {platform.description || 'No description available'}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PlatformCard; 