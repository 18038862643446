// src/pages/CustomViewRenderPage.js
import React, { useEffect, useState, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { 
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../services/authService';
import { 
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

// Update CustomToolbar component to include export button
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport 
        csvOptions={{
          fileName: 'custom-view-export',
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter
        quickFilterProps={{ debounceMs: 500 }}
      />
    </GridToolbarContainer>
  );
}

const CustomViewRenderPage = () => {
  const { id: viewId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  
  // Add state for view details and overview collapse
  const [viewDetails, setViewDetails] = useState(null);
  const [overviewExpanded, setOverviewExpanded] = useState(false);
  
  const [columns, setColumns] = useState([]);
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Pagination state for client-side pagination
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  // Add state for sorting
  const [sortModel, setSortModel] = useState([]);
  
  // Update filter state to include quickFilter
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterValues: [],
    logicOperator: 'and',
  });

  // Update filter handler for client-side quick filtering
  const handleFilterChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  // Add sorting handler
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  // Add column processing
  const processedColumns = useMemo(() => {
    return columns.map(column => ({
      field: column.id,
      headerName: column.label,
      minWidth: 120,
      flex: 1,
      // Add renderCell for special fields
      renderCell: (params) => {
        const value = params.row[params.field];
        
        // Handle date fields
        if (['created_at', 'updated_at', 'last_contact_date'].includes(params.field)) {
          return value ? moment(value).format('YYYY-MM-DD hh:mm A') : '—';
        }
        
        // Handle boolean fields
        if (typeof value === 'boolean') {
          return value ? 'Yes' : 'No';
        }
        
        // Handle null/undefined values
        if (value === null || value === undefined) {
          return '—';
        }
        
        return value;
      }
    }));
  }, [columns]);

  const fetchRenderedView = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/crm/custom-views/${viewId}/render/`);
      
      // Debugging log
      console.log('API Response:', response.data);
      console.log('API Columns:', response.data.columns);
      if (response.data.leads.length > 0) {
        console.log('First lead example:', response.data.leads[0]);
      }
      
      // Transform columns to match field names correctly
      const transformedColumns = response.data.columns.map((column) => {
        let fieldId;
        
        // Use field_name property from the backend if it exists
        if (column.field_name) {
          fieldId = column.field_name;
        }
        // Handle different column types as fallback
        else if (column.column_type === 'definition' && column.lead_field_definition) {
          fieldId = `field_values__${column.lead_field_definition.id}__value`;
        } 
        else if (column.column_type === 'intake' && column.intake_field) {
          fieldId = `intake_values__${column.intake_field.id}__value`;
        }
        else {
          // For base/regular fields, use the field_identifier
          fieldId = column.field_identifier;
        }
        
        return {
          id: fieldId,  // This is the key - use the exact field name from backend
          label: column.display_name,
          minWidth: column.width || 120,
          flex: 1,
          sortable: true,
          columnType: column.column_type
        };
      });

      // Transform leads data to handle object values
      const transformedLeads = response.data.leads.map(lead => {
        const transformedLead = { ...lead };
        
        // Transform object values only for display purposes
        Object.keys(transformedLead).forEach(key => {
          if (typeof transformedLead[key] === 'object' && transformedLead[key] !== null) {
            // Handle foreign key objects
            if (transformedLead[key].name) {
              transformedLead[key] = transformedLead[key].name;
            } else if (transformedLead[key].email) {
              transformedLead[key] = transformedLead[key].email;
            } else {
              transformedLead[key] = String(transformedLead[key].id);
            }
          }
        });
        
        return transformedLead;
      });

      // Debug column-to-lead field mapping
      if (transformedLeads.length > 0) {
        console.log('Transformed columns:', transformedColumns);
        console.log('Transformed first lead:', transformedLeads[0]);
        
        // Check that each column has a corresponding field in the lead data
        transformedColumns.forEach(col => {
          const firstLead = transformedLeads[0];
          console.log(`Column ${col.label} (${col.id}): ${firstLead[col.id] !== undefined ? 'Present' : 'MISSING'}`);
        });
      }

      setColumns(transformedColumns);
      setLeads(transformedLeads);
      setTotalLeads(response.data.total_count || transformedLeads.length);
      setError(null);
    } catch (err) {
      console.error('Error fetching rendered view:', err);
      setError('Error loading custom view.');
    }
    setLoading(false);
  };

  // Add function to fetch view details
  const fetchViewDetails = async () => {
    try {
      const response = await api.get(`/crm/custom-views/${viewId}/`);
      setViewDetails(response.data);
    } catch (error) {
      console.error('Error fetching view details:', error);
    }
  };

  useEffect(() => {
    fetchViewDetails();
    fetchRenderedView();
  }, [viewId]);

  // Helper function to format filters for display
  const formatFilter = (filter) => {
    const operatorMap = {
      eq: 'equals',
      neq: 'not equals',
      gt: 'greater than',
      lt: 'less than',
      gte: 'greater than or equal to',
      lte: 'less than or equal to',
      contains: 'contains',
      not_contains: 'does not contain',
      starts_with: 'starts with',
      ends_with: 'ends with',
      in: 'is any of',
      not_in: 'is none of',
      all: 'is all of'
    };

    return `${filter.column} ${operatorMap[filter.operator] || filter.operator} ${filter.value}`;
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Top header with Back button and view name */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Button variant="outlined" onClick={() => navigate('/crm/custom-views')}>
          Back to Custom Views
        </Button>
        <Typography variant="h5" sx={{ ml: 2 }}>
          {viewDetails?.name || 'Custom View Details'}
        </Typography>
      </Box>

      {/* Overview Card */}
      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
               onClick={() => setOverviewExpanded(!overviewExpanded)}>
            <Typography variant="h6" component="div">
              Overview & Applied Filters
            </Typography>
            <IconButton size="small" sx={{ ml: 1 }}>
              {overviewExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          
          <Collapse in={overviewExpanded}>
            <List dense>
              <ListItem>
                <ListItemText 
                  primary="Account"
                  secondary={viewDetails?.account_details?.name || viewDetails?.campaign_details?.account?.name || 'N/A'}
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Campaign"
                  secondary={viewDetails?.campaign_details?.name || 'N/A'}
                />
              </ListItem>
              <Divider sx={{ my: 1 }} />
              <ListItem>
                <ListItemText 
                  primary="Applied Filters"
                  secondary={
                    viewDetails?.filters?.length ? (
                      <List dense>
                        {viewDetails.filters.map((filter, index) => (
                          <ListItem key={index}>
                            <ListItemText 
                              secondary={formatFilter(filter)}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : 'No filters applied'
                  }
                />
              </ListItem>
            </List>
          </Collapse>
        </CardContent>
      </Card>

      {/* Display error if present */}
      {error && (
        <Box sx={{ mb: 2, p: 2, backgroundColor: theme.palette.error.light, color: theme.palette.error.contrastText }}>
          <Typography>{error}</Typography>
        </Box>
      )}

      {/* DataGrid for displaying leads */}
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={leads}
          columns={processedColumns}
          getRowId={(row) => row.id}
          loading={loading}
          
          // Client-side filtering
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={handleFilterChange}

          // Client-side sorting
          sortingMode="client"
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}

          // Disable selection
          disableRowSelectionOnClick
          
          // Let the user reorder & resize columns
          disableColumnReorder={false}
          disableColumnResize={false}
          
          // Client-side pagination
          pagination
          paginationMode="client"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          
          // Add toolbar with quick filter
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { 
                debounceMs: 500,
                quickFilterParser: (searchInput) => searchInput.split(',').map(value => value.trim()),
              },
            },
          }}
          
          // Enable quick filter on all columns
          getQuickFilterValues={row => {
            return Object.keys(row).map(key => {
              const value = row[key];
              if (value === null || value === undefined) return '';
              return String(value);
            });
          }}
          
          sx={{
            backgroundColor: theme.palette.background.paper,
            '& .MuiDataGrid-root': {
              border: `1px solid ${theme.palette.divider}`,
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${theme.palette.divider}`,
              color: theme.palette.text.primary,
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderBottom: `2px solid ${theme.palette.divider}`,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: `2px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.default,
            },
            '& .MuiDataGrid-row': {
              '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            '& .MuiTablePagination-root': {
              color: theme.palette.text.primary,
            },
            '& .MuiDataGrid-menuIcon': {
              color: theme.palette.text.primary,
            },
            '& .MuiDataGrid-sortIcon': {
              color: theme.palette.text.primary,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: theme.palette.text.primary,
            },
            '& .MuiDataGrid-columnSeparator': {
              color: theme.palette.divider,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomViewRenderPage;