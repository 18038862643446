// src/pages/CustomViewBuilder.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Chip,
  FormGroup,
  Grid,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/authService';

// Define base columns (these are standard fields in your Lead model)
const BASE_COLUMNS = [
  { field_identifier: 'id', display_name: 'ID' },
  { field_identifier: 'lead_type', display_name: 'Lead Type' },
  { field_identifier: 'campaign', display_name: 'Campaign' },
  { field_identifier: 'funnel', display_name: 'Funnel' },
  { field_identifier: 'first_name', display_name: 'First Name' },
  { field_identifier: 'last_name', display_name: 'Last Name' },
  { field_identifier: 'email', display_name: 'Email' },
  { field_identifier: 'phone_number', display_name: 'Phone Number' },
  { field_identifier: 'current_step', display_name: 'Current Step' },
  { field_identifier: 'status', display_name: 'Status' },
  { field_identifier: 'assigned_to', display_name: 'Assigned To' },
  { field_identifier: 'last_updated_by', display_name: 'Last Updated By' },
  { field_identifier: 'channel', display_name: 'Channel' },
  { field_identifier: 'source', display_name: 'Source' },
  { field_identifier: 'score', display_name: 'Score' },
  { field_identifier: 'conversion_probability', display_name: 'Conversion Probability' },
  { field_identifier: 'created_at', display_name: 'Created At' },
  { field_identifier: 'updated_at', display_name: 'Updated At' },
  { field_identifier: 'last_contact_date', display_name: 'Last Contact Date' },
  { field_identifier: 'is_disqualified', display_name: 'Is Disqualified' }
];

// Define D2C columns
const D2C_COLUMNS = [
  { field_identifier: 'address', display_name: 'Address' },
  { field_identifier: 'city', display_name: 'City' },
  { field_identifier: 'state', display_name: 'State' },
  { field_identifier: 'zip_code', display_name: 'Zip Code' }
];

// Define B2B columns
const B2B_COLUMNS = [
  { field_identifier: 'company', display_name: 'Company' },
  { field_identifier: 'job_title', display_name: 'Job Title' },
  { field_identifier: 'industry', display_name: 'Industry' }
];

// Add field type definitions
const FIELD_TYPES = {
  // Base Lead Fields
  'id': 'number',
  'lead_type': 'choice',
  'campaign': 'foreign_key',
  'funnel': 'foreign_key',
  'first_name': 'text',
  'last_name': 'text',
  'email': 'text',
  'phone_number': 'text',
  'current_step': 'foreign_key',
  'status': 'foreign_key',
  'assigned_to': 'foreign_key',
  'channel': 'text',
  'source': 'text',
  'score': 'number',
  'conversion_probability': 'number',
  'created_at': 'datetime',
  'updated_at': 'datetime',
  'last_contact_date': 'datetime',
  'is_qualified': 'boolean',
  'is_disqualified': 'boolean',
  'is_dead': 'boolean',
  
  // D2C Fields
  'date_of_birth': 'date',
  'age': 'number',
  'gender': 'choice',
  'marital_status': 'text',
  
  // B2B Fields
  'company': 'text',
  'job_title': 'text',
  'industry': 'text',
  'company_size': 'text',
  'annual_revenue': 'number'
};

const OPERATORS_BY_TYPE = {
  text: [
    { value: 'contains', label: 'Contains' },
    { value: 'not_contains', label: 'Does not contain' },
    { value: 'eq', label: 'Equals' },
    { value: 'neq', label: 'Not equals' },
    { value: 'starts_with', label: 'Starts with' },
    { value: 'ends_with', label: 'Ends with' }
  ],
  multi_select: [
    { value: 'in', label: 'Is any of' },
    { value: 'not_in', label: 'Is none of' },
    { value: 'all', label: 'Is all of' }
  ],
  number: [
    { value: 'eq', label: 'Equals' },
    { value: 'neq', label: 'Not equals' },
    { value: 'gt', label: 'Greater than' },
    { value: 'lt', label: 'Less than' },
    { value: 'gte', label: 'Greater than or equal' },
    { value: 'lte', label: 'Less than or equal' }
  ],
  datetime: [
    { value: 'eq', label: 'Equals' },
    { value: 'neq', label: 'Not equals' },
    { value: 'gt', label: 'After' },
    { value: 'lt', label: 'Before' },
    { value: 'gte', label: 'After or on' },
    { value: 'lte', label: 'Before or on' }
  ],
  date: [
    { value: 'eq', label: 'Equals' },
    { value: 'neq', label: 'Not equals' },
    { value: 'gt', label: 'After' },
    { value: 'lt', label: 'Before' },
    { value: 'gte', label: 'After or on' },
    { value: 'lte', label: 'Before or on' }
  ],
  boolean: [
    { value: 'eq', label: 'Is' },
    { value: 'neq', label: 'Is not' }
  ],
  choice: [
    { value: 'eq', label: 'Is' },
    { value: 'neq', label: 'Is not' }
  ],
  foreign_key: [
    { value: 'eq', label: 'Is' },
    { value: 'neq', label: 'Is not' }
  ]
};

// Add choices for specific fields
const FIELD_CHOICES = {
  lead_type: [
    { value: 'b2b', label: 'B2B' },
    { value: 'd2c', label: 'D2C' }
  ],
  gender: [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' }
  ]
};

// Add filter category definitions
const FILTER_CATEGORIES = {
  campaign: {
    title: 'Campaign Filters',
    fields: ['funnel', 'channel', 'source']
  },
  lead: {
    title: 'Lead Filters',
    fields: [
      'first_name', 'last_name', 'email', 'phone_number',
      'current_step', 'status', 'assigned_to', 'score',
      'conversion_probability', 'created_at', 'updated_at',
      'last_contact_date', 'is_qualified', 'is_disqualified', 'is_dead'
    ]
  },
  d2c: {
    title: 'D2C Model Filters',
    fields: ['date_of_birth', 'age', 'gender', 'marital_status']
  },
  b2b: {
    title: 'B2B Model Filters',
    fields: ['company', 'job_title', 'industry', 'company_size', 'annual_revenue']
  },
  intake: {
    title: 'Intake Filters',
    fields: [] // Will be populated from campaign data
  },
  custom: {
    title: 'Custom Field Filters',
    fields: [] // Will be populated from campaign data
  }
};

const CustomViewBuilder = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [viewName, setViewName] = useState('');
  const [viewDescription, setViewDescription] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('');
  const [sharedUsers, setSharedUsers] = useState([]);
  const [folders, setFolders] = useState([]);
  const [users, setUsers] = useState([]);
  const [campaignModelType, setCampaignModelType] = useState(null);
  const [campaignFunnels, setCampaignFunnels] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);

  const [columns, setColumns] = useState({
    base: [],
    d2c: [],
    b2b: [],
    intake: [],
    definition: []
  });

  // Add state for storing all available steps
  const [availableSteps, setAvailableSteps] = useState([]);

  // Fetch initial data
  useEffect(() => {
    fetchAccounts();
    fetchFolders();
  }, []);

  // Fetch campaigns when account changes
  useEffect(() => {
    if (selectedAccountId) {
      fetchCampaigns(selectedAccountId);
    }
  }, [selectedAccountId]);

  // Fetch columns when campaign changes
  useEffect(() => {
    if (selectedCampaign) {
      fetchColumns();
    }
  }, [selectedCampaign]);

  // Update fetchCampaignFunnels to include funnel info with steps
  const fetchCampaignFunnels = async () => {
    if (!selectedCampaign) return;
    try {
      const response = await api.get(`/crm/campaigns/${selectedCampaign}/funnels/`);
      setCampaignFunnels(response.data);
      
      // Extract steps from all funnels, including funnel info
      const steps = response.data.reduce((allSteps, funnel) => {
        funnel.steps.forEach(step => {
          if (!allSteps.some(s => s.id === step.id)) {
            allSteps.push({
              ...step,
              funnelName: funnel.name, // Add funnel name to step info
              funnelId: funnel.id
            });
          }
        });
        return allSteps;
      }, []);
      
      setAvailableSteps(steps);
    } catch (error) {
      console.error('Error fetching campaign funnels:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await api.get('/crm/accounts/');
      setAccounts(response.data);
      if (response.data.length > 0) {
        setSelectedAccountId(response.data[0].id);
        setSelectedAccount(response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchCampaigns = async (accountId) => {
    try {
      const response = await api.get(`/crm/accounts/${accountId}/campaigns/`);
      setCampaigns(response.data);
      setSelectedCampaign('');
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchColumns = async () => {
    if (!selectedCampaign) {
      setColumns({
        base: BASE_COLUMNS.map(col => ({
          ...col,
          column_type: 'base'
        })),
        d2c: D2C_COLUMNS.map(col => ({
          ...col,
          column_type: 'd2c'
        })),
        b2b: B2B_COLUMNS.map(col => ({
          ...col,
          column_type: 'b2b'
        })),
        intake: [],
        definition: []
      });
      return;
    }

    try {
      const [intakeSectionsResponse, definitionResponse] = await Promise.all([
        api.get(`/crm/intake_sections/?campaign_id=${selectedCampaign}`),
        api.get(`/crm/lead_field_definitions/?campaign_id=${selectedCampaign}`)
      ]);

      // Flatten intake fields from all sections
      const intakeFields = intakeSectionsResponse.data.reduce((fields, section) => {
        return fields.concat(section.intake_fields.map(field => ({
          field_identifier: field.id.toString(),
          display_name: `${section.name} - ${field.name}`,
          column_type: 'intake',
          intake_field: field.id,
          section_name: section.name
        })));
      }, []);

      setColumns({
        base: BASE_COLUMNS.map(col => ({
          ...col,
          column_type: 'base'
        })),
        d2c: D2C_COLUMNS.map(col => ({
          ...col,
          column_type: 'd2c'
        })),
        b2b: B2B_COLUMNS.map(col => ({
          ...col,
          column_type: 'b2b'
        })),
        intake: intakeFields,
        definition: definitionResponse.data.map(field => ({
          field_identifier: field.id.toString(),
          display_name: field.name,
          column_type: 'definition',
          lead_field_definition: field.id
        }))
      });
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  };

  const fetchFolders = async () => {
    try {
      const response = await api.get('/crm/folders/');
      setFolders(response.data);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  const handleColumnToggle = (columnId) => {
    setSelectedColumns(prev =>
      prev.includes(columnId)
        ? prev.filter(id => id !== columnId)
        : [...prev, columnId]
    );
  };

  const addFilter = (category) => {
    // Get the first available field from the category
    let availableFields = [];
    if (category === 'intake') {
      availableFields = columns.intake.map(col => col.field_identifier);
    } else if (category === 'custom') {
      availableFields = columns.definition.map(col => col.field_identifier);
    } else {
      availableFields = FILTER_CATEGORIES[category].fields;
    }

    const newFilter = {
      column: availableFields[0] || '', // Set first available field as default
      operator: '',
      value: ''
    };

    setFilters(prevFilters => [...prevFilters, newFilter]);
  };

  const updateFilter = (index, field, value) => {
    setFilters(prevFilters => {
      const newFilters = [...prevFilters];
      newFilters[index] = {
        ...newFilters[index],
        [field]: value,
        // Reset value when changing column or operator, except for funnel which needs special handling
        ...(field === 'column' && newFilters[index].column !== 'funnel' && { operator: '', value: '' }),
        ...(field === 'operator' && { value: '' })
      };
      return newFilters;
    });
  };

  const removeFilter = (index) => {
    setFilters(prevFilters => prevFilters.filter((_, i) => i !== index));
  };

  const handleNext = () => {
    if (isStepValid(activeStep)) {
      setActiveStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSave = async () => {
    const payload = {
      name: viewName,
      description: viewDescription,
      campaign: selectedCampaign,
      folder: selectedFolder,
      columns: prepareColumnPayload(),
      filters: prepareFilterPayload(),
      shares: sharedUsers.map(user => ({
        user: user.id,  // Just send the user ID instead of the full user object
        can_edit: user.permissions.can_edit || false,
        can_share: user.permissions.can_share || false,
        can_delete: user.permissions.can_delete || false,
      })),
    };

    try {
      await api.post('/crm/custom-views/', payload);
      navigate('/crm/custom-views');
    } catch (error) {
      console.error('Error saving view:', error);
      // You might want to add error handling UI here
    }
  };

  const handleCancel = () => {
    navigate('/crm/custom-views');  // Navigate back to custom views page
  };

  const steps = ['Select Columns', 'Apply Filters', 'View Settings'];

  const handleCampaignChange = async (campaignId) => {
    setSelectedCampaign(campaignId);
    if (campaignId) {
      try {
        const response = await api.get(`/crm/campaigns/${campaignId}/`);
        setCampaignModelType(response.data.campaign_model?.name || null);
        // Transform users_with_access to the format we need
        const campaignUsers = response.data.users_with_access.map(access => ({
          id: access.user.id,
          name: `${access.user.first_name} ${access.user.last_name}`.trim() || access.user.email,
          email: access.user.email,
          permissions: {
            can_edit: false,
            can_view: true,
            can_share: false,
            can_delete: false
          }
        }));
        setUsers(campaignUsers);
      } catch (error) {
        console.error('Error fetching campaign details:', error);
      }
    } else {
      setCampaignModelType(null);
      setUsers([]);
    }
  };

  const handleSelectAllColumns = (category) => {
    const categoryColumns = columns[category] || [];
    const categoryColumnIds = categoryColumns.map(col => col.field_identifier);
    
    if (selectedColumns.length === 0 || !categoryColumnIds.every(id => selectedColumns.includes(id))) {
      // If none or some are selected, select all
      setSelectedColumns(prev => [...new Set([...prev, ...categoryColumnIds])]);
    } else {
      // If all are selected, deselect all
      setSelectedColumns(prev => prev.filter(id => !categoryColumnIds.includes(id)));
    }
  };

  const renderColumnSection = (category, title) => {
    const categoryColumns = columns[category] || [];
    const isAllSelected = categoryColumns.length > 0 && 
      categoryColumns.every(col => selectedColumns.includes(col.field_identifier));
    
    // Determine if section should be disabled
    const isDisabled = !selectedCampaign || // Disable if no campaign selected
                      (category === 'd2c' ? campaignModelType?.toLowerCase() !== 'd2c' :
                      category === 'b2b' ? campaignModelType?.toLowerCase() !== 'b2b' : false);

    if (isDisabled && categoryColumns.length === 0) {
      return null; // Don't render the section if it's disabled and empty
    }

    return (
      <Accordion 
        disabled={isDisabled}
        sx={{
          opacity: isDisabled ? 0.7 : 1,
          '& .MuiAccordionSummary-root.Mui-disabled': {
            opacity: 0.7
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{title}</Typography>
          {!selectedCampaign ? (
            <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
              (Select a campaign first)
            </Typography>
          ) : isDisabled && (
            <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
              (Not available for this campaign type)
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={categoryColumns.some(col => selectedColumns.includes(col.field_identifier)) && !isAllSelected}
                  onChange={() => handleSelectAllColumns(category)}
                  disabled={isDisabled}
                />
              }
              label="Select All"
            />
            <Divider sx={{ my: 1 }} />
            {categoryColumns.map(column => (
              <FormControlLabel
                key={column.field_identifier}
                control={
                  <Checkbox
                    checked={selectedColumns.includes(column.field_identifier)}
                    onChange={() => handleColumnToggle(column.field_identifier)}
                    disabled={isDisabled}
                  />
                }
                label={column.display_name}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    );
  };

  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return selectedCampaign && selectedColumns.length > 0;
      case 1:
        return filters.every(filter => 
          filter.column && filter.operator && filter.value !== ''
        );
      case 2:
        return viewName.trim() !== '';
      default:
        return true;
    }
  };

  // Add a function to get all available columns as a flat array
  const getAllColumns = useCallback(() => {
    const allColumns = [];
    
    // Add base columns
    allColumns.push(...columns.base);
    
    // Add D2C columns if applicable
    if (campaignModelType?.toLowerCase() === 'd2c') {
      allColumns.push(...columns.d2c);
    }
    
    // Add B2B columns if applicable
    if (campaignModelType?.toLowerCase() === 'b2b') {
      allColumns.push(...columns.b2b);
    }
    
    // Add intake and definition columns
    allColumns.push(...columns.intake);
    allColumns.push(...columns.definition);
    
    return allColumns;
  }, [columns, campaignModelType]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Account</InputLabel>
              <Select
                value={selectedAccountId}
                onChange={(e) => {
                  setSelectedAccountId(e.target.value);
                  const account = accounts.find(acc => acc.id === e.target.value);
                  setSelectedAccount(account);
                }}
                label="Select Account"
              >
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Campaign</InputLabel>
              <Select
                value={selectedCampaign}
                onChange={(e) => handleCampaignChange(e.target.value)}
                label="Select Campaign"
                disabled={!selectedAccountId}
              >
                {campaigns.map(campaign => (
                  <MenuItem key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {renderColumnSection('base', 'Base Lead Columns')}
            {renderColumnSection('d2c', 'D2C Fields')}
            {renderColumnSection('b2b', 'B2B Fields')}
            {renderColumnSection('intake', 'Intake Fields')}
            {renderColumnSection('definition', 'Lead Definition Fields')}
            {selectedColumns.length === 0 && (
              <Typography color="error" sx={{ mt: 2 }}>
                Please select at least one column
              </Typography>
            )}
          </Box>
        );

      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            {/* Campaign Filters */}
            {renderFilterSection('campaign', FILTER_CATEGORIES.campaign.fields)}

            {/* Lead Filters */}
            {renderFilterSection('lead', FILTER_CATEGORIES.lead.fields)}

            {/* Campaign Model Filters (D2C or B2B) */}
            {campaignModelType?.toLowerCase() === 'd2c' &&
              renderFilterSection('d2c', FILTER_CATEGORIES.d2c.fields)}
            {campaignModelType?.toLowerCase() === 'b2b' &&
              renderFilterSection('b2b', FILTER_CATEGORIES.b2b.fields)}

            {/* Intake Filters */}
            {columns.intake.length > 0 &&
              renderFilterSection('intake', columns.intake.map(col => col.field_identifier))}

            {/* Custom Field Filters */}
            {columns.definition.length > 0 &&
              renderFilterSection('custom', columns.definition.map(col => col.field_identifier))}
          </Box>
        );

      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="View Name"
              value={viewName}
              onChange={(e) => setViewName(e.target.value)}
              sx={{ mb: 2 }}
              error={viewName.trim() === ''}
              helperText={viewName.trim() === '' ? 'View name is required' : ''}
            />
            <TextField
              fullWidth
              label="Description"
              value={viewDescription}
              onChange={(e) => setViewDescription(e.target.value)}
              multiline
              rows={3}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Folder (Optional)</InputLabel>
              <Select
                value={selectedFolder}
                onChange={(e) => setSelectedFolder(e.target.value)}
                label="Select Folder (Optional)"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {folders.map(folder => (
                  <MenuItem key={folder.id} value={folder.id}>
                    {folder.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {renderUserSharing()}
          </Box>
        );

      default:
        return 'Unknown step';
    }
  };

  const prepareColumnPayload = () => {
    return selectedColumns.map((columnId, index) => {
      // Find the column in our columns object
      let columnData;
      for (const type in columns) {
        const found = columns[type].find(col => 
          col.field_identifier === columnId || col.id === columnId
        );
        if (found) {
          columnData = found;
          break;
        }
      }

      if (!columnData) return null;

      return {
        column_type: columnData.column_type,
        field_identifier: columnData.field_identifier,
        display_name: columnData.display_name,
        order: index + 1,
        visible: true,
        width: '200', // default width
        alignment: 'left', // default alignment
        intake_field: columnData.intake_field,
        lead_field_definition: columnData.lead_field_definition
      };
    }).filter(Boolean);
  };

  // Add function to handle permission changes
  const handlePermissionChange = (userId, permission) => {
    setSharedUsers(prev => {
      const userIndex = prev.findIndex(u => u.id === userId);
      if (userIndex === -1) {
        // Add user with initial permissions
        return [...prev, {
          id: userId,
          permissions: {
            can_edit: permission === 'can_edit',
            can_view: true,
            can_share: permission === 'can_share',
            can_delete: permission === 'can_delete'
          }
        }];
      } else {
        // Update existing user's permissions
        const updatedUsers = [...prev];
        updatedUsers[userIndex] = {
          ...updatedUsers[userIndex],
          permissions: {
            ...updatedUsers[userIndex].permissions,
            [permission]: !updatedUsers[userIndex].permissions[permission]
          }
        };
        return updatedUsers;
      }
    });
  };

  // Update handleAddUser to default all permissions to false
  const handleAddUser = (userId) => {
    const userToAdd = users.find(u => u.id === userId);
    if (!userToAdd) return;

    setSharedUsers(prev => [...prev, {
      id: userId,
      name: userToAdd.name,
      email: userToAdd.email,
      permissions: {
        can_edit: false,
        can_view: false,
        can_share: false,
        can_delete: false
      }
    }]);
  };

  // Add function to remove a shared user
  const handleRemoveUser = (userId) => {
    setSharedUsers(prev => prev.filter(user => user.id !== userId));
  };

  // Update the sharing section in step 3
  const renderUserSharing = () => {
    // Get users that haven't been added yet
    const availableUsers = users.filter(user => 
      !sharedUsers.some(shared => shared.id === user.id)
    );

    return (
      <Box>
        <Typography variant="subtitle1" gutterBottom>
          Share with Users
        </Typography>
        
        {/* User selector dropdown */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Add User</InputLabel>
          <Select
            value=""
            onChange={(e) => handleAddUser(e.target.value)}
            label="Add User"
          >
            {availableUsers.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.name || user.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* List of shared users */}
        <Paper variant="outlined" sx={{ mt: 2 }}>
          <List>
            {sharedUsers.map((user, index) => (
              <React.Fragment key={user.id}>
                {index > 0 && <Divider />}
                <ListItem
                  secondaryAction={
                    <IconButton 
                      edge="end" 
                      aria-label="remove" 
                      onClick={() => handleRemoveUser(user.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={user.name || user.email}
                    secondary={
                      <Box sx={{ mt: 1 }}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_view}
                                onChange={() => handlePermissionChange(user.id, 'can_view')}
                                size="small"
                              />
                            }
                            label="View"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_edit}
                                onChange={() => handlePermissionChange(user.id, 'can_edit')}
                                size="small"
                              />
                            }
                            label="Edit"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_share}
                                onChange={() => handlePermissionChange(user.id, 'can_share')}
                                size="small"
                              />
                            }
                            label="Share"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={user.permissions.can_delete}
                                onChange={() => handlePermissionChange(user.id, 'can_delete')}
                                size="small"
                              />
                            }
                            label="Delete"
                          />
                        </FormGroup>
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
            {sharedUsers.length === 0 && (
              <ListItem>
                <ListItemText 
                  primary="No users added"
                  secondary="Select users from the dropdown above to share this view"
                />
              </ListItem>
            )}
          </List>
        </Paper>
      </Box>
    );
  };

  // Update renderFilterSection function
  const renderFilterSection = (category, fields) => {
    if (fields.length === 0) return null;

    // Get filters for this category
    const categoryFilters = filters.filter(filter => fields.includes(filter.column));

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{FILTER_CATEGORIES[category].title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ p: 1 }}>
            {categoryFilters.length > 0 ? (
              categoryFilters.map((filter, index) => (
                <FilterRow 
                  key={index}
                  filter={filter}
                  index={filters.indexOf(filter)} // Use actual index from main filters array
                  availableFields={fields}
                  onUpdate={updateFilter}
                  onRemove={removeFilter}
                />
              ))
            ) : (
              <Typography 
                color="text.secondary" 
                sx={{ 
                  fontStyle: 'italic',
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1 
                }}
              >
                <InfoIcon fontSize="small" />
                No filters added for this section
              </Typography>
            )}
            <Button
              startIcon={<AddIcon />}
              onClick={() => addFilter(category)}
              variant="outlined"
              size="small"
              sx={{ mt: 1 }}
            >
              Add Filter
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  // Update FilterRow component to use multi-select operators for funnel
  const FilterRow = ({ filter, index, availableFields, onUpdate, onRemove }) => {
    const selectedColumn = getAllColumns().find(col => col.field_identifier === filter.column);
    let fieldType = selectedColumn ? FIELD_TYPES[selectedColumn.field_identifier] || 'text' : 'text';
    
    // Override field type for funnel, status, and current_step to use multi-select operators
    if (selectedColumn?.field_identifier === 'funnel' || 
        selectedColumn?.field_identifier === 'status' ||
        selectedColumn?.field_identifier === 'current_step') {
      fieldType = 'multi_select';
    }
    
    const operators = OPERATORS_BY_TYPE[fieldType] || OPERATORS_BY_TYPE.text;

    return (
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Field</InputLabel>
            <Select
              value={filter.column}
              onChange={(e) => onUpdate(index, 'column', e.target.value)}
              label="Field"
            >
              {getAllColumns()
                .filter(col => availableFields.includes(col.field_identifier))
                .map(column => (
                  <MenuItem key={column.field_identifier} value={column.field_identifier}>
                    {column.display_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Operator</InputLabel>
            <Select
              value={filter.operator}
              onChange={(e) => onUpdate(index, 'operator', e.target.value)}
              label="Operator"
            >
              {operators.map(op => (
                <MenuItem key={op.value} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          {renderFilterValueInput(fieldType, filter, index, selectedColumn, onUpdate)}
        </Grid>
        <Grid item xs={1}>
          <IconButton 
            onClick={() => onRemove(index)} 
            color="error"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  // Add function to fetch lead statuses
  const fetchLeadStatuses = async () => {
    try {
      const response = await api.get('/crm/lead-statuses/');
      setLeadStatuses(response.data);
    } catch (error) {
      console.error('Error fetching lead statuses:', error);
    }
  };

  // Update useEffect to fetch statuses when status filter is added
  useEffect(() => {
    const hasFunnelFilter = filters.some(filter => filter.column === 'funnel');
    const hasStatusFilter = filters.some(filter => filter.column === 'status');
    const hasStepFilter = filters.some(filter => filter.column === 'current_step');

    if ((hasFunnelFilter || hasStepFilter) && selectedCampaign) {
      fetchCampaignFunnels();
    }
    
    if (hasStatusFilter) {
      fetchLeadStatuses();
    }
  }, [filters, selectedCampaign]);

  // Update renderFilterValueInput to show funnel info with steps
  const renderFilterValueInput = (fieldType, filter, index, selectedColumn, onUpdate) => {
    // Special handling for current_step field
    if (selectedColumn?.field_identifier === 'current_step') {
      return (
        <FormControl fullWidth size="small">
          <InputLabel>Select Steps</InputLabel>
          <Select
            multiple
            value={filter.value ? filter.value.split(',') : []}
            onChange={(e) => onUpdate(index, 'value', e.target.value.join(','))}
            label="Select Steps"
            renderValue={(selected) => {
              const selectedSteps = availableSteps
                .filter(step => selected.includes(step.id.toString()))
                .map(step => `${step.name} (${step.funnelName})`);
              return selectedSteps.join(', ');
            }}
          >
            {/* Group steps by funnel */}
            {campaignFunnels.map((funnel) => [
              <ListSubheader key={`funnel-${funnel.id}`}>
                {funnel.name}
              </ListSubheader>,
              ...availableSteps
                .filter(step => step.funnelId === funnel.id)
                .map((step) => (
                  <MenuItem 
                    key={step.id} 
                    value={step.id.toString()}
                    sx={{ pl: 4 }} // Indent steps under funnel header
                  >
                    <Checkbox 
                      checked={filter.value ? 
                        filter.value.split(',').includes(step.id.toString()) : 
                        false
                      }
                    />
                    <ListItemText 
                      primary={step.name}
                      secondary={`${step.step_type}`}
                    />
                  </MenuItem>
                ))
            ]).flat()}
          </Select>
        </FormControl>
      );
    }

    // Special handling for funnel field
    if (selectedColumn?.field_identifier === 'funnel') {
      return (
        <FormControl fullWidth size="small">
          <InputLabel>Select Funnels</InputLabel>
          <Select
            multiple
            value={filter.value ? filter.value.split(',') : []}
            onChange={(e) => onUpdate(index, 'value', e.target.value.join(','))}
            label="Select Funnels"
            renderValue={(selected) => {
              const selectedFunnels = campaignFunnels
                .filter(funnel => selected.includes(funnel.id.toString()))
                .map(funnel => funnel.name);
              return selectedFunnels.join(', ');
            }}
          >
            {campaignFunnels.map((funnel) => (
              <MenuItem key={funnel.id} value={funnel.id.toString()}>
                <Checkbox 
                  checked={filter.value ? 
                    filter.value.split(',').includes(funnel.id.toString()) : 
                    false
                  }
                />
                <ListItemText primary={funnel.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    // Special handling for status field
    if (selectedColumn?.field_identifier === 'status') {
      return (
        <FormControl fullWidth size="small">
          <InputLabel>Select Statuses</InputLabel>
          <Select
            multiple
            value={filter.value ? filter.value.split(',') : []}
            onChange={(e) => onUpdate(index, 'value', e.target.value.join(','))}
            label="Select Statuses"
            renderValue={(selected) => {
              const selectedStatuses = leadStatuses
                .filter(status => selected.includes(status.id.toString()))
                .map(status => status.name);
              return selectedStatuses.join(', ');
            }}
          >
            {leadStatuses.map((status) => (
              <MenuItem key={status.id} value={status.id.toString()}>
                <Checkbox 
                  checked={filter.value ? 
                    filter.value.split(',').includes(status.id.toString()) : 
                    false
                  }
                />
                <ListItemText primary={status.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    // Rest of the existing switch statement for other field types
    switch (fieldType) {
      case 'boolean':
        return (
          <FormControl fullWidth size="small">
            <InputLabel>Value</InputLabel>
            <Select
              value={filter.value}
              onChange={(e) => onUpdate(index, 'value', e.target.value)}
              label="Value"
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        );

      case 'choice':
        if (FIELD_CHOICES[selectedColumn?.field_identifier]) {
          return (
            <FormControl fullWidth size="small">
              <InputLabel>Value</InputLabel>
              <Select
                value={filter.value}
                onChange={(e) => onUpdate(index, 'value', e.target.value)}
                label="Value"
              >
                {FIELD_CHOICES[selectedColumn.field_identifier].map(choice => (
                  <MenuItem key={choice.value} value={choice.value}>
                    {choice.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
        return null;

      case 'datetime':
        return (
          <TextField
            fullWidth
            size="small"
            type="datetime-local"
            value={filter.value}
            onChange={(e) => onUpdate(index, 'value', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        );

      case 'date':
        return (
          <TextField
            fullWidth
            size="small"
            type="date"
            value={filter.value}
            onChange={(e) => onUpdate(index, 'value', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        );

      case 'number':
        return (
          <TextField
            fullWidth
            size="small"
            type="number"
            value={filter.value}
            onChange={(e) => onUpdate(index, 'value', e.target.value)}
            label="Value"
          />
        );

      case 'foreign_key':
        // For now, treat foreign key fields as text input
        // You might want to add specific dropdowns for each foreign key type
        return (
          <TextField
            fullWidth
            size="small"
            value={filter.value}
            onChange={(e) => onUpdate(index, 'value', e.target.value)}
            label="Value"
          />
        );

      default: // text
        return (
          <TextField
            fullWidth
            size="small"
            value={filter.value}
            onChange={(e) => onUpdate(index, 'value', e.target.value)}
            label="Value"
          />
        );
    }
  };

  // Add the prepareFilterPayload function
  const prepareFilterPayload = () => {
    return filters.map((filter, index) => {
      let col = filter.column;
      // Check if the filter column matches an intake field
      const intakeMatch = columns.intake.find(c => c.field_identifier === col);
      if (intakeMatch) {
        col = `intake_field_${col}`;
      }
      // Check if it matches a definition (custom) field
      const definitionMatch = columns.definition.find(c => c.field_identifier === col);
      if (definitionMatch) {
        col = `lead_field_${col}`;
      }

      const isMultiSelectField = ['funnel', 'status', 'current_step'].includes(filter.column);
      const isMultiSelectOperator = ['in', 'not_in', 'all'].includes(filter.operator);
      
      // Format the value based on field type and operator
      let value = filter.value;
      
      if (isMultiSelectField || isMultiSelectOperator) {
        // Handle multi-select values - always use comma-separated string format
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        // Value is already a string, so keep it as is
      } else if (typeof value === 'boolean' || typeof value === 'number') {
        // For other types, convert to strings
        value = String(value);
      }

      return {
        ...filter,
        order: index,
        column: col,
        value: value
      };
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5" component="h1">
          Create Custom View
        </Typography>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>

      <Card>
        <CardContent>
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {getStepContent(activeStep)}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!isStepValid(activeStep)}
              >
                Save View
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!isStepValid(activeStep)}
              >
                Next
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomViewBuilder; 