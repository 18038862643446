// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Container, 
  CircularProgress,
  Alert,
  Paper,
  Avatar
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { setCredentials, selectCurrentUser } from '../redux/authSlice';
import { api, apiWithoutAuth } from '../services/authService';
import { Link } from 'react-router-dom';
import logoCircle from '../assets/NovauraCircleIcon.png'


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');
  const [loginAttempted, setLoginAttempted] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        // Ensure credentials (cookies) are included in the request
        const response = await apiWithoutAuth.get('/accounts/csrf/', { withCredentials: true });
        const csrfToken = response.data.csrfToken;

        // Store CSRF token in localStorage
        localStorage.setItem('csrfToken', csrfToken);
        setCsrfToken(csrfToken);
      } catch (err) {
        console.error('Error fetching CSRF token:', err);
        setError('Failed to initialize login. Please try again.');
      }
    };
    getCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    setLoginAttempted(true);

    if (!username.trim() || !password.trim()) {
        setError('Username and password are required');
        setIsLoading(false);
        return;
    }

    try {
        // Use the new login endpoint
        const response = await apiWithoutAuth.post('/accounts/login/', { username, password });
        const { user, access, refresh } = response.data;

        // Dispatch user data and tokens to Redux store
        dispatch(setCredentials({ user, token: access, refreshToken: refresh }));

        // Store tokens and user data in localStorage
        localStorage.setItem('token', access);
        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('user', JSON.stringify(user));

        // Redirect to dashboard after successful login
        navigate('/crm/dashboard');
    } catch (err) {
        console.error('Login error:', err);
        if (err.response) {
            console.error('Error response:', err.response);
            setError(`Error ${err.response.status}: ${err.response.data.error || 'An error occurred during login'}`);
        } else if (err.request) {
            console.error('Error request:', err.request);
            setError('No response received from server. Please try again.');
        } else {
            console.error('Error message:', err.message);
            setError('An error occurred during login. Please try again.');
        }
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: '#2E2E2E',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper elevation={6} sx={{ p: 3, backgroundColor: '#3C3C3C' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ m: 1, width: 64, height: 64 }}>
              <img src={logoCircle} alt="Novaura Icon" style={{ width: '100%' }} />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ color: '#FFFFFF' }}>
              Sign in to Novaura CRM
            </Typography>
            {error && (
              <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                {error}
              </Alert>
            )}
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={isLoading}
                InputLabelProps={{ style: { color: '#FFFFFF' } }} // White text
                InputProps={{ style: { color: '#FFFFFF' } }} // White text for input
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                InputLabelProps={{ style: { color: '#FFFFFF' } }} // White text for label
                InputProps={{ style: { color: '#FFFFFF' } }} // White text for input
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading || !csrfToken}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
              </Button>
              <Typography variant="body2" align="center" sx={{ color: '#FFFFFF' }}>
                Don&apos;t have an account? <Link to="/signup" style={{ color: '#FF5A79' }}>Sign up!</Link>
              </Typography>
              <Typography variant="body2" align="center" sx={{ mt: 1, color: '#FFFFFF' }}>
                <Link to="/forgot-password" style={{ color: '#FF5A79' }}>Forgot Password?</Link>
              </Typography>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                  <a href="https://novaura.io/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#FF5A79' }}>
                    Terms
                  </a>
                </Typography>
                <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                  <a href="https://novaura.io/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#FF5A79' }}>
                    Privacy
                  </a>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;