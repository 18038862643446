import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Badge,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TablePagination,
  CircularProgress,
  Skeleton
} from '@mui/material';
import {
  Schedule,
  Warning,
  PriorityHigh,
  CheckCircle,
  Person,
  NotificationsActive,
  Add,
  Edit,
  Delete,
  TrendingUp,
  Block,
  ExpandMore
} from '@mui/icons-material';
import { format, differenceInDays } from 'date-fns';
import ScheduledReachOutsView from './ScheduledReachOutsView';

const TaskManagementView = ({
  tasks,
  scheduledCallbacks,
  leads,
  onEditTask,
  onDeleteTask,
  onCreateTask,
  onEditCallback,
  onDeleteCallback,
  onCreateCallback,
  onLeadClick,
  userTimezone,
  theme,
  isLoading = false
}) => {
  const [selectedView, setSelectedView] = useState('tasks');
  const [staleLeads, setStaleLeads] = useState([]);
  const [taskFilter, setTaskFilter] = useState('all');

  // Pagination state for tasks
  const [pendingPage, setPendingPage] = useState(0);
  const [pendingRowsPerPage, setPendingRowsPerPage] = useState(5);
  const [completedPage, setCompletedPage] = useState(0);
  const [completedRowsPerPage, setCompletedRowsPerPage] = useState(5);

  // Helper function to convert text to title case
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  useEffect(() => {
    const calculateStaleLeads = () => {
      return leads.filter(lead => {
        const daysSinceContact = lead.last_contact_date ? 
          differenceInDays(new Date(), new Date(lead.last_contact_date)) : 
          Number.POSITIVE_INFINITY;

        return (
          (daysSinceContact <= 7 && !lead.daily_followup_done) ||
          (daysSinceContact <= 14 && !lead.alternate_followup_done) ||
          (daysSinceContact <= 30 && !lead.weekly_followup_done) ||
          (daysSinceContact > 30 && lead.status !== 'dead')
        );
      });
    };

    setStaleLeads(calculateStaleLeads());
  }, [leads]);

  const formatLeadName = (lead) => {
    return `${lead.first_name || ''} ${lead.last_name || ''}`.trim() || 'N/A';
  };

  const getStatusName = (lead) => {
    if (lead.status_name) {
      return lead.status_name;
    }
    return 'No Status';
  };

  const getTaskStatusName = (status) => {
    if (!status) return 'N/A';
    return typeof status === 'object' ? status.name : status;
  };

  const getStatusColor = (lead) => {
    const statusName = lead.status_name?.toLowerCase() || '';
    
    switch (statusName) {
      case 'dead':
        return 'error';
      case 'qualified':
        return 'success';
      case 'disqualified':
        return 'error';
      case 'new contact':
        return 'info';
      case 'no status':
        return 'default';
      default:
        return 'primary';
    }
  };

  const categorizeLeads = () => {
    const categories = {
      daily: [],
      alternate: [],
      weekly: [],
      dead: []
    };

    staleLeads.forEach(lead => {
      const daysSinceContact = lead.last_contact_date ? 
        differenceInDays(new Date(), new Date(lead.last_contact_date)) : 
        Number.POSITIVE_INFINITY;

      const formattedLead = {
        ...lead,
        displayName: formatLeadName(lead),
        statusName: getStatusName(lead)
      };

      if (daysSinceContact <= 7) categories.daily.push(formattedLead);
      else if (daysSinceContact <= 14) categories.alternate.push(formattedLead);
      else if (daysSinceContact <= 30) categories.weekly.push(formattedLead);
      else categories.dead.push(formattedLead);
    });

    return categories;
  };

  const renderTasksView = () => {
    const pendingTasks = tasks.filter(task => task.status !== 'completed');
    const completedTasks = tasks.filter(task => task.status === 'completed');

    // Create paginated arrays for pending and completed tasks
    const paginatedPendingTasks = pendingTasks.slice(
      pendingPage * pendingRowsPerPage,
      pendingPage * pendingRowsPerPage + pendingRowsPerPage
    );
    const paginatedCompletedTasks = completedTasks.slice(
      completedPage * completedRowsPerPage,
      completedPage * completedRowsPerPage + completedRowsPerPage
    );

    const renderLoadingRows = (colSpan) => (
      <>
        {[...Array(3)].map((_, index) => (
          <TableRow key={index}>
            <TableCell colSpan={colSpan}>
              <Skeleton animation="wave" />
            </TableCell>
          </TableRow>
        ))}
      </>
    );

    return (
      <Box>
        {/* Pending Tasks Accordion */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              '& .MuiAccordionSummary-expandIconWrapper': {
                color: 'primary.contrastText',
              },
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mr={2}>
              <Typography variant="h6">
                Pending Tasks ({isLoading ? '...' : pendingTasks.length})
              </Typography>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={(e) => {
                  e.stopPropagation();
                  onCreateTask();
                }}
                disabled={isLoading}
                sx={{
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Create Task
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Assigned To</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    renderLoadingRows(6)
                  ) : (
                    <>
                      {paginatedPendingTasks.map(task => (
                        <TableRow key={task.id}>
                          <TableCell>{task.title}</TableCell>
                          <TableCell>
                            {task.due_date ? format(new Date(task.due_date), 'MMM dd, yyyy HH:mm') : 'N/A'}
                          </TableCell>
                          <TableCell>
                            <Chip 
                              label={task.priority}
                              color={
                                task.priority === 'high' ? 'error' :
                                task.priority === 'medium' ? 'warning' : 
                                'success'
                              }
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            {task.assigned_to?.first_name 
                              ? `${task.assigned_to.first_name} ${task.assigned_to.last_name}`
                              : 'Unassigned'}
                          </TableCell>
                          <TableCell>
                            <Chip 
                              label={getTaskStatusName(task.status)}
                              color={task.status === 'completed' ? 'success' : 'default'}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton size="small" onClick={() => onEditTask(task)}>
                              <Edit />
                            </IconButton>
                            <IconButton size="small" onClick={() => onDeleteTask(task.id)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {pendingTasks.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Typography color="textSecondary">No pending tasks</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={pendingTasks.length}
                page={pendingPage}
                onPageChange={(event, newPage) => setPendingPage(newPage)}
                rowsPerPage={pendingRowsPerPage}
                onRowsPerPageChange={(event) => {
                  setPendingRowsPerPage(parseInt(event.target.value, 10));
                  setPendingPage(0);
                }}
                labelRowsPerPage="Tasks per page:"
                labelDisplayedRows={({ from, to, count }) => 
                  isLoading ? 
                    <CircularProgress size={16} /> : 
                    `${from}-${to} of ${count} pending tasks`
                }
                rowsPerPageOptions={[5, 10, 25]}
                disabled={isLoading}
              />
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        {/* Completed Tasks Accordion */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              backgroundColor: 'success.main',
              color: 'success.contrastText',
              '& .MuiAccordionSummary-expandIconWrapper': {
                color: 'success.contrastText',
              },
            }}
          >
            <Typography variant="h6">
              Completed Tasks ({isLoading ? '...' : completedTasks.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} sx={{ backgroundColor: 'grey.50' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell>Assigned To</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    renderLoadingRows(6)
                  ) : (
                    <>
                      {paginatedCompletedTasks.map(task => (
                        <TableRow key={task.id}>
                          <TableCell>{task.title}</TableCell>
                          <TableCell>
                            {task.due_date ? format(new Date(task.due_date), 'MMM dd, yyyy HH:mm') : 'N/A'}
                          </TableCell>
                          <TableCell>
                            <Chip 
                              label={task.priority}
                              color={
                                task.priority === 'high' ? 'error' :
                                task.priority === 'medium' ? 'warning' : 
                                'success'
                              }
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            {task.assigned_to?.first_name 
                              ? `${task.assigned_to.first_name} ${task.assigned_to.last_name}`
                              : 'Unassigned'}
                          </TableCell>
                          <TableCell>
                            <Chip 
                              label={getTaskStatusName(task.status)}
                              color={task.status === 'completed' ? 'success' : 'default'}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton size="small" onClick={() => onEditTask(task)}>
                              <Edit />
                            </IconButton>
                            <IconButton size="small" onClick={() => onDeleteTask(task.id)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      {completedTasks.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Typography color="textSecondary">No completed tasks</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={completedTasks.length}
                page={completedPage}
                onPageChange={(event, newPage) => setCompletedPage(newPage)}
                rowsPerPage={completedRowsPerPage}
                onRowsPerPageChange={(event) => {
                  setCompletedRowsPerPage(parseInt(event.target.value, 10));
                  setCompletedPage(0);
                }}
                labelRowsPerPage="Tasks per page:"
                labelDisplayedRows={({ from, to, count }) => 
                  isLoading ? 
                    <CircularProgress size={16} /> : 
                    `${from}-${to} of ${count} completed tasks`
                }
                rowsPerPageOptions={[5, 10, 25]}
                disabled={isLoading}
              />
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={selectedView} onChange={(_, newValue) => setSelectedView(newValue)}>
          <Tab 
            value="tasks" 
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <CheckCircle />
                General Tasks
              </Box>
            } 
          />
          <Tab 
            value="callbacks" 
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <Schedule />
                Scheduled Callbacks
              </Box>
            } 
          />
        </Tabs>
      </Box>

      {selectedView === 'tasks' && renderTasksView()}
      {selectedView === 'callbacks' && (
        <ScheduledReachOutsView
          scheduledReachOuts={scheduledCallbacks}
          onCreateCallback={onCreateCallback}
          onEditCallback={onEditCallback}
          onDeleteCallback={onDeleteCallback}
          userTimezone={userTimezone}
          theme={theme}
        />
      )}

      <Alert severity="info" sx={{ mt: 2 }}>
        Follow-up Decay Methodology:
        • First week: Daily follow-ups
        • Second week: Alternate day follow-ups
        • Weeks 3-4: Weekly follow-ups
        • After 30 days: Lead marked as "dead" - requires reactivation
      </Alert>
    </Box>
  );
};

TaskManagementView.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    due_date: PropTypes.string,
    priority: PropTypes.string,
    status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string
      })
    ]),
    assigned_to: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  })).isRequired,
  scheduledCallbacks: PropTypes.array.isRequired,
  leads: PropTypes.array.isRequired,
  onEditTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  onCreateTask: PropTypes.func.isRequired,
  onEditCallback: PropTypes.func.isRequired,
  onDeleteCallback: PropTypes.func.isRequired,
  onCreateCallback: PropTypes.func.isRequired,
  onLeadClick: PropTypes.func.isRequired,
  userTimezone: PropTypes.string,
  theme: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
};

export default TaskManagementView;
